import React from "react";
import classNames from "classnames";
import memoize from "@RHCommerceDev/utils/memoize";
import { SwatchCarouselProps } from "./types";
import clsx from "clsx";
import Arrows from "./Arrows";
import CloseIcon from "./CloseIcon";
import SwatchOverlay from "./SwatchOverlay";

const SwatchCarousel: React.FC<SwatchCarouselProps> = ({
  swatches,
  currentSelectedSwatch,
  itemsToShow,
  height,
  width,
  SwatchDetail,
  carouselRef,
  isEnlarge,
  largeWidth,
  largeHeight,
  productConfigData,
  onClose,
  endRef,
  handleMouseLeave,
  handleImageClick,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  handleImageDragStart,
  handleShowInfo,
  handleScrollLeft,
  handleScrollRight
}) => {
  const baseClasses = "border-t-[1.5px] border-b-[1.5px] border-transparent";
  const beforeClasses =
    "before:absolute before:bg-swatch-overlay-gradient before:opacity-40 before:mix-blend-multiply before:inset-x-0 before:top-0 before:h-1 before:z-10";
  const afterClasses =
    "after:absolute after:bg-swatch-overlay-gradient after:opacity-40 after:mix-blend-multiply after:inset-x-0 after:bottom-0 after:h-1 after:z-10";
  const selectedSwatchClass = `!border-black ${beforeClasses} ${afterClasses}`;
  return (
    <div
      id={"component-pdp-swatch-carousel_swatch_carousel"}
      data-testid={"component-pdp-swatch-carousel_swatch_carousel"}
      className={clsx(
        isEnlarge
          ? "absolute top-0 z-50 bg-black/80 flex items-center justify-center w-full overflow-hidden"
          : "relative px-10"
      )}
    >
      <div
        className={classNames(
          " w-full overflow-hidden whitespace-nowrap flex items-center relative active:cursor-grabbing",
          { ["hidden"]: SwatchDetail }
        )}
        ref={carouselRef}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div className="flex transition-all duration-950 ease-swatch-carousel-custom-bezier">
          {swatches.slice(0, itemsToShow).map((swatch, index) => {
            const isSelected = swatch?.status === "selected";
            const isUnavailable = swatch?.status === "unavailable";
            if (isEnlarge && isUnavailable) {
              return null;
            }
            return (
              <div
                id={`swatch-${index}`}
                className={clsx(
                  "relative",
                  baseClasses,
                  isSelected && selectedSwatchClass,
                  isUnavailable && "opacity-30"
                )}
                onClick={() => handleImageClick(swatch, isSelected)}
                key={swatch?.swatchId}
                onDragStart={handleImageDragStart}
                draggable="false"
                style={{
                  height: isEnlarge
                    ? largeHeight || "calc(100vh - 197px)"
                    : height,
                  width: isEnlarge ? largeWidth : width,
                  background: `url(${swatch?.swatchImageLg?.url}) center top`
                }}
              >
                <SwatchOverlay
                  swatch={swatch}
                  isSelected={isSelected}
                  isUnavailable={isUnavailable}
                  handleShowInfo={() => handleShowInfo(swatch)}
                  isEnlarge={!!isEnlarge}
                  productConfigData={productConfigData}
                />
              </div>
            );
          })}
          <div ref={endRef} />
        </div>
      </div>
      {!SwatchDetail && (
        <div
          className={clsx(
            "absolute self-center w-full z-10 top-0",
            isEnlarge && "top-[48%]"
          )}
        >
          <Arrows
            scrollPrev={handleScrollLeft}
            prevClassName={
              !isEnlarge ? "!-left-10 h-[136px]" : "py-4 bg-white bg-opacity-60"
            }
            nextClassName={
              !isEnlarge ? "!right-10 h-[136px]" : "py-4 bg-white bg-opacity-60"
            }
            scrollNext={handleScrollRight}
          />
        </div>
      )}

      {onClose && (
        <CloseIcon
          className=" mix-blend-difference text-white text-xl absolute top-0 right-0 mx-2.5 my-3 cursor-pointer"
          onClick={onClose}
        />
      )}
    </div>
  );
};

export default memoize(SwatchCarousel);
