import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { disable } from "utils/scrollLock";
import { sleep } from "utils/sleep";

export const usePDPSwatchCarouselData = props => {
  const {
    swatchGroup,
    finishSwatchGroups,
    height = "136px",
    width = "87px",
    largeWidth = "197px",
    largeHeight = "786px",
    handleSwatchSelect,
    swatchOptionStatus,
    productConfigData,
    isUserTouchConfig
  } = props;

  const carouselRef = useRef<HTMLDivElement>(null);
  const [selectedSwatch, setSelectedSwatch] = useState<{
    [key: string]: ProductSwatch;
  } | null>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [selectedMaterialName, setSelectedMaterialName] =
    useState<string>("fabric");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [SwatchDetail, setSwatchDetail] = useState<ProductSwatch | null>(null);
  const [dragDistance, setDragDistance] = useState(0);
  const [enlargedSwatchSelected, setEnlargedSwatchSelected] =
    useState<ProductSwatch | null>(null);

  const [isEnlarge, setEnlarge] = useState<boolean>(false);
  const processSwatches = (
    groups: ProductSwatchGroup[],
    types: string[],
    optionStatus?: Record<string, any>
  ) => {
    const swatches: ProductSwatchGroup[] | any = [];
    let isAnySwatchSelected: boolean = false;
    groups?.forEach(group => {
      types.forEach(type => {
        group[type]?.forEach(swatch => {
          const swatchOptionId = swatch?.options
            ?.map(item => item.id)
            .join("_");
          if (
            !optionStatus ||
            Object.keys(optionStatus || {})?.includes(swatchOptionId)
          ) {
            isAnySwatchSelected =
              isAnySwatchSelected ||
              optionStatus?.[swatchOptionId] === "selected";
            swatches.push({
              ...swatch,
              swatchOptionId,
              status: optionStatus?.[swatchOptionId]
            });
          }
        });
      });
    });

    if (!isUserTouchConfig && isAnySwatchSelected) {
      setIsPaused(true);
      sleep(3000).then(() => {
        setIsPaused(false);
      });
      return swatches.sort((a, b) => {
        if (a.status === "selected" && b.status !== "selected") return -1;
        if (a.status !== "selected" && b.status === "selected") return 1;
        return 0;
      });
    }
    return swatches;
  };

  const processedSwatches = useMemo(
    () => ({
      swatches: processSwatches(
        swatchGroup,
        ["customSwatches", "stockedSwatches"],
        swatchOptionStatus
      ),
      materialName: "fabric"
    }),
    [swatchGroup, swatchOptionStatus]
  );

  const processedFinishSwatches = useMemo(() => {
    const swatches = processSwatches(finishSwatchGroups, ["stockedSwatches"]);
    return swatches.length
      ? { swatches, materialName: finishSwatchGroups[0]?.groupMaterial }
      : {};
  }, [finishSwatchGroups]);

  const currentSwatches = useMemo(
    () =>
      selectedMaterialName === "fabric" && processedSwatches?.swatches?.length
        ? processedSwatches
        : processedFinishSwatches,
    [processedSwatches, processedFinishSwatches, selectedMaterialName]
  );

  const { ref: endRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false
  });

  useEffect(() => {
    if (
      !processedSwatches?.swatches?.length &&
      processedFinishSwatches?.materialName
    ) {
      setSelectedMaterialName(processedFinishSwatches?.materialName);
    }
  }, [processedSwatches, processedFinishSwatches]);

  useEffect(() => {
    if (inView && currentSwatches?.swatches) {
      setItemsToShow(prev =>
        Math.min(prev + 10, currentSwatches?.swatches?.length)
      );
    }
  }, [inView, currentSwatches?.swatches?.length]);

  useEffect(() => {
    const carousel = carouselRef.current;
    let animationFrame: number;

    const scrollMarquee = () => {
      if (!isDragging && carousel) {
        carousel.scrollLeft += 1;
        if (
          carousel.scrollLeft >= carousel.scrollWidth / 2 &&
          itemsToShow >= currentSwatches?.swatches?.length
        ) {
          carousel.scrollLeft = 0;
        }
      }
      animationFrame = requestAnimationFrame(scrollMarquee);
    };

    if (!isPaused && carousel) {
      animationFrame = requestAnimationFrame(scrollMarquee);
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [isPaused, isDragging, itemsToShow, currentSwatches?.swatches?.length]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    const carousel = carouselRef.current;
    if (carousel) {
      setIsDragging(true);
      setStartX(e.pageX - carousel.offsetLeft);
      setScrollLeft(carousel.scrollLeft);
      setDragDistance(0);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;
      const carousel = carouselRef.current;
      if (carousel) {
        const walk = e.pageX - carousel.offsetLeft - startX;
        setDragDistance(Math.abs(walk));
        setIsPaused(true);
        carousel.scrollLeft = scrollLeft - walk;
      }
    },
    [isDragging, startX, scrollLeft]
  );

  const handleMouseUp = useCallback(() => setIsDragging(false), []);

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleImageClick = useCallback(
    (swatch: ProductSwatch, isSelected: boolean = false) => {
      if (isDragging || dragDistance > 5) return;
      if (!isSelected) {
        handleSwatchSelect(swatch);
        setSelectedSwatch(prev => ({
          ...prev,
          [selectedMaterialName]: swatch
        }));
        setIsPaused(true);
        setEnlargedSwatchSelected(isEnlarge ? swatch : null);
      }
      if (!isEnlarge) {
        handleShowInfo(swatch);
      }
    },
    [selectedMaterialName, isDragging, dragDistance]
  );

  const handleShowInfo = useCallback((swatch: ProductSwatch) => {
    setSwatchDetail(swatch);
    setIsPaused(true);
  }, []);

  const handleImageDragStart = useCallback(
    (e: React.DragEvent) => e.preventDefault(),
    []
  );

  const handleScrollLeft = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      setIsPaused(true);
      const imageWidth = parseInt(isEnlarge ? width : largeWidth);
      const visibleWidth = carousel.clientWidth;
      const imagesPerRow = Math.floor(visibleWidth / imageWidth);
      const newScrollLeft = Math.max(
        carousel.scrollLeft - imagesPerRow * imageWidth,
        0
      );
      carousel.scrollLeft = newScrollLeft;
    }
  };

  const handleScrollRight = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      setIsPaused(true);
      const imageWidth = parseInt(!isEnlarge ? width : largeWidth);
      const visibleWidth = carousel.clientWidth;
      const imagesPerRow = Math.floor(visibleWidth / imageWidth);
      const newScrollLeft = Math.min(
        carousel.scrollLeft + imagesPerRow * imageWidth,
        carousel.scrollWidth - visibleWidth
      );
      carousel.scrollLeft = newScrollLeft;
    }
  };

  const getBorderClass = useCallback(
    (materialName?: string) => {
      return selectedMaterialName === materialName
        ? "border-b border-black"
        : "";
    },
    [selectedMaterialName]
  );

  const currentSelectedSwatch = selectedSwatch?.[selectedMaterialName];

  const handleEnlargeClick = () => {
    setEnlarge(true);
    setEnlargedSwatchSelected(null);
    disable();
  };

  return {
    height,
    width,
    largeWidth,
    largeHeight,
    carouselRef,
    selectedSwatch,
    isPaused,
    selectedMaterialName,
    isDragging,
    startX,
    scrollLeft,
    itemsToShow,
    SwatchDetail,
    currentSelectedSwatch,
    currentSwatches,
    processedSwatches,
    processedFinishSwatches,
    enlargedSwatchSelected,
    isEnlarge,
    productConfigData,
    setEnlarge,
    setSelectedSwatch,
    setIsPaused,
    setSelectedMaterialName,
    setIsDragging,
    setStartX,
    setScrollLeft,
    setItemsToShow,
    setSwatchDetail,
    endRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    handleImageClick,
    handleShowInfo,
    handleImageDragStart,
    getBorderClass,
    handleScrollLeft,
    handleScrollRight,
    handleEnlargeClick
  };
};
