import React from "react";
import PDPSwatchCarousel from "@RHCommerceDev/component-pdp-swatch-carousel";
import PDPConfigurator from "@RHCommerceDev/component-pdp-configurator";
import PDPCTA from "@RHCommerceDev/component-pdp-cta";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePDPConfiguratorAndCTAData } from "./usePDPConfiguratorAndCTAData";
import AddToCartDialog from "dialogs/AddToCartDialog";
import isEmpty from "lodash.isempty";
import { useNewURLStructureParams } from "@RHCommerceDev/hooks/useParams";
import { useRelatedProductCardAnalytics } from "components/RelatedProductCard/useRelatedProductCardAnalytics";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { PDPConfiguratorAndCTAProps } from "./type";

const PDPConfiguratorAndCTA: React.FC<PDPConfiguratorAndCTAProps> = props => {
  const {
    data,
    swatchData,
    priceRangeDisplay,
    loading,
    lineItem,
    postalCode,
    onZipChange,
    onOrderTypeInfo,
    cartDialog,
    monogramOrder,
    pli,
    customProductConfig,
    giftCardTo,
    giftCardFrom,
    multiSkuComponentsDataV2,
    skuDetails,
    qty,
    customSelectedOptions,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    isDisabled,
    memberPrice,
    regularPrice,
    orderType,
    selectedOptions,
    isUserTouchConfig,
    isMultiSku,
    onProductConfigChange,
    handleAddToCartClick,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange,
    getPreBillMessage,
    handleSwatchSelect,
    swatchOptionStatus,
    deliveryDate
  } = usePDPConfiguratorAndCTAData(props);

  const { triggerAddToCartAnalyticsEvent } = useRelatedProductCardAnalytics();
  const urlParams = useNewURLStructureParams();
  const excludedTypes = ["Color", "Fabric"];
  const selectedOptsString = selectedOptions
    ?.filter(option => !excludedTypes.includes(option.type))
    ?.map(option => `${option.value} ${option.type}`)
    ?.join(", ");

  return (
    <div
      id={"component-pdp-configurator-and-cta"}
      data-testid={"component-pdp-configurator-and-cta"}
    >
      <PDPSwatchCarousel
        swatchGroup={swatchData?.swatchGroups}
        finishSwatchGroups={swatchData?.finishSwatchGroups}
        handleSwatchSelect={handleSwatchSelect}
        swatchOptionStatus={swatchOptionStatus}
        isUserTouchConfig={isUserTouchConfig}
        productConfigData={{
          selectedOptsString,
          memberPrice,
          regularPrice,
          deliveryDate,
          postalCode,
          orderType: orderType || ""
        }}
      />
      <div className={"flex items-end px-10"}>
        <PDPConfigurator
          isLoading={loading}
          availableOptions={lineItem?.availableOptions || []}
          handleOptionsSelect={onProductConfigChange}
        />

        <PDPCTA
          memberPrice={memberPrice}
          regularPrice={regularPrice}
          deliveryDate={deliveryDate}
          zipCode={postalCode}
          onZipChange={onZipChange}
          onOrderTypeInfo={onOrderTypeInfo}
          orderType={orderType || ""}
          isDisabled={isDisabled || loading}
          handlePlaceOrderClick={handleAddToCartClick}
        />
      </div>

      {cartDialog && (
        <AddToCartDialog
          monogramOrder={monogramOrder}
          open={cartDialog}
          onClose={() => handleCloseAddToCartDialog()}
          productId={data?.id}
          fullSkuId={skuDetails?.fullSkuId ?? ""}
          atgSkuId={pli?.sku?.fulfillmentEta?.atgSkuId ?? ""}
          multiSkuComponentsDataV2={multiSkuComponentsDataV2}
          spo={
            isMultiSku(pli?.sku?.fulfillmentEta?.fullSkuId) &&
            data?.metaProduct === true
              ? multiSkuComponentsDataV2?.isSPO
              : pli?.sku?.restrictions?.spo
          }
          productDisplayName={data?.displayName}
          onCompleted={cartDetails => {
            if (!processEnvServer) {
              triggerAddToCartAnalyticsEvent(
                data,
                cartDetails,
                qty,
                skuDetails,
                pli,
                urlParams
              );
            }
          }}
          preBillMessage={getPreBillMessage()}
          options={customSelectedOptions}
          qty={qty}
          giftCardTo={giftCardTo}
          giftCardFrom={giftCardFrom}
          salePriceLabel={data?.priceRangeDisplay?.salePriceLabel || ""}
          pricing={
            !isEmpty(multiSkuComponentsDataV2)
              ? metaSkuPricing
              : pli?.sku?.info?.skuPriceInfo
          }
          customInfo={customProductConfig}
          setConfirmed={setConfirmed}
          confirmed={confirmed}
          productAddons={addonDataAddToCart}
          productAddonsInfo={data?.productAddons?.productAddonsInfo}
          multiSkuComponents={skuDetails?.components || undefined}
          onAddOnConfigChange={onAddOnConfigChange}
          panelProdData={panelProdData}
          panelProdConfigurationData={panelProdConfigurationData}
          productAddonDisplayOptions={data?.productAddonDisplayOptions || ""}
          productLineItemOptions={
            lineItem?.availableOptions as ProductAvailableOptionSet[]
          }
          incomingPanelProdConfigData={loading ?? false}
        />
      )}
    </div>
  );
};

export default memoize(PDPConfiguratorAndCTA);
