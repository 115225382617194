import React, { useState, useRef, useEffect } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import clsx from "clsx";

interface ExpandableTextProps {
  content: string;
  maxHeight: number;
  className?: string;
  buttonClassName?: string;
  containerProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  content,
  maxHeight,
  containerProps,
  className = "",
  buttonClassName = ""
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { pageContent } = usePageContent();

  useEffect(() => {
    if (contentRef.current && contentRef.current.scrollHeight > maxHeight) {
      setCanExpand(true);
    }
  }, [content, maxHeight]);

  return (
    <div
      id={"component-pdp-swatch-carousel_expandable_text"}
      data-testid={"component-pdp-swatch-carousel_expandable_text"}
      className={clsx("relative flex items-start", containerProps?.className)}
    >
      <div
        ref={contentRef}
        id={"expandable_text"}
        className={clsx(
          "transition-all duration-300 overflow-hidden",
          className
        )}
        style={{
          ...(!isExpanded && { maxHeight: `${maxHeight}px` })
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {canExpand && (
        <button
          onClick={() => setIsExpanded(prev => !prev)}
          className={clsx(
            "m-0 p-0 absolute cursor-pointer bottom-0 left-[30%] mix-blend-difference  text-white",
            buttonClassName
          )}
        >
          {isExpanded ? pageContent?.Show_Less : pageContent?.Show_More}
        </button>
      )}
    </div>
  );
};

export default memoize(ExpandableText);
