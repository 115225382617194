import React from "react";

export const SmallRuler: React.FC<
  React.SVGProps<SVGSVGElement> & {
    selectedUnit?: string;
  }
> = props => {
  const { selectedUnit = "inches", ...restProps } = props;
  const centimetersSVG = (
    <>
      <path d="M0.94043 0.824219V18.3242" stroke="black" />
      <path d="M9.51025 0.824219V6.82422" stroke="black" />
      <path d="M18.0806 0.824219V6.82422" stroke="black" />
      <path d="M26.6504 0.824219V6.82422" stroke="black" />
      <path d="M35.2202 0.824219V18.3242" stroke="black" />
      <path d="M43.7905 0.824219V6.82422" stroke="black" />
      <path d="M52.3604 0.824219V6.82422" stroke="black" />
      <path d="M60.9307 0.824219V6.82422" stroke="black" />
      <path d="M69.5005 0.824219V18.3242" stroke="black" />
      <path d="M78.0703 0.824219V6.82422" stroke="black" />
      <path d="M86.6406 0.824219V6.82422" stroke="black" />
      <path d="M95.2104 0.824219V6.82422" stroke="black" />
      <path d="M103.78 0.824219V18.3242" stroke="black" />
      <path d="M112.351 0.824219V6.82422" stroke="black" />
      <path d="M120.92 0.824219V6.82422" stroke="black" />
      <path d="M129.49 0.824219V6.82422" stroke="black" />
      <path d="M138.061 0.824219V18.3242" stroke="black" />
      <path d="M146.63 0.824219V6.82422" stroke="black" />
      <path d="M155.2 0.824219V6.82422" stroke="black" />
      <path d="M163.771 0.824219V6.82422" stroke="black" />
      <path d="M172.34 0.824219V18.3242" stroke="black" />
      <path d="M180.911 0.824219V6.82422" stroke="black" />
      <path d="M189.48 0.824219V6.82422" stroke="black" />
      <path d="M198.05 0.824219V6.82422" stroke="black" />
      <path d="M206.621 0.824219V18.3242" stroke="black" />
      <path d="M215.19 0.824219V6.82422" stroke="black" />
      <path d="M223.761 0.824219V6.82422" stroke="black" />
      <path d="M232.331 0.824219V6.82422" stroke="black" />
      <path d="M240.9 0.824219V18.3242" stroke="black" />
      <path d="M249.471 0.824219V6.82422" stroke="black" />
      <path d="M258.041 0.824219V6.82422" stroke="black" />
      <path d="M266.61 0.824219V6.82422" stroke="black" />
      <path d="M275.181 0.824219V18.3242" stroke="black" />
      <path d="M283.75 0.824219V6.82422" stroke="black" />
      <path d="M292.32 0.824219V6.82422" stroke="black" />
      <path d="M300.891 0.824219V6.82422" stroke="black" />
      <path d="M309.46 0.824219V18.3242" stroke="black" />
      <path d="M318.031 0.824219V6.82422" stroke="black" />
      <path d="M326.601 0.824219V6.82422" stroke="black" />
      <path d="M335.171 0.824219V6.82422" stroke="black" />
      <path d="M343.741 0.824219V18.3242" stroke="black" />
      <path d="M352.311 0.824219V6.82422" stroke="black" />
      <path d="M360.881 0.824219V6.82422" stroke="black" />
      <path d="M369.451 0.824219V6.82422" stroke="black" />
      <path d="M378.021 0.824219V18.3242" stroke="black" />
      <path d="M386.591 0.824219V6.82422" stroke="black" />
      <path d="M395.161 0.824219V6.82422" stroke="black" />
      <path d="M403.731 0.824219V6.82422" stroke="black" />
      <path d="M412.301 0.824219V18.3242" stroke="black" />
      <path d="M420.871 0.824219V6.82422" stroke="black" />
      <path d="M429.441 0.824219V6.82422" stroke="black" />
      <path d="M438.011 0.824219V6.82422" stroke="black" />
      <path d="M446.581 0.824219V18.3242" stroke="black" />
      <path d="M455.151 0.824219V6.82422" stroke="black" />
      <path d="M463.721 0.824219V6.82422" stroke="black" />
      <path d="M472.291 0.824219V6.82422" stroke="black" />
      <path d="M480.861 0.824219V18.3242" stroke="black" />
      <path d="M489.431 0.824219V6.82422" stroke="black" />
      <path d="M498.001 0.824219V6.82422" stroke="black" />
      <path d="M506.571 0.824219V6.82422" stroke="black" />
      <path d="M515.141 0.824219V18.3242" stroke="black" />
    </>
  );
  const inchesSVG = (
    <>
      <path d="M0.94043 0.824219V18.3242" stroke="black" />
      <path d="M22.9404 0.824219V6.82422" stroke="black" />
      <path d="M44.9404 0.824219V6.82422" stroke="black" />
      <path d="M66.9404 0.824219V6.82422" stroke="black" />
      <path d="M88.9404 0.824219V18.3242" stroke="black" />
      <path d="M110.94 0.824219V6.82422" stroke="black" />
      <path d="M132.94 0.824219V6.82422" stroke="black" />
      <path d="M154.94 0.824219V6.82422" stroke="black" />
      <path d="M176.94 0.824219V18.3242" stroke="black" />
      <path d="M198.94 0.824219V6.82422" stroke="black" />
      <path d="M220.94 0.824219V6.82422" stroke="black" />
      <path d="M242.94 0.824219V6.82422" stroke="black" />
      <path d="M264.94 0.824219V18.3242" stroke="black" />
      <path d="M286.94 0.824219V6.82422" stroke="black" />
      <path d="M308.94 0.824219V6.82422" stroke="black" />
      <path d="M330.94 0.824219V6.82422" stroke="black" />
      <path d="M352.94 0.824219V18.3242" stroke="black" />
      <path d="M374.94 0.824219V6.82422" stroke="black" />
      <path d="M396.94 0.824219V6.82422" stroke="black" />
      <path d="M418.94 0.824219V6.82422" stroke="black" />
      <path d="M440.94 0.824219V18.3242" stroke="black" />
      <path d="M462.94 0.824219V6.82422" stroke="black" />
      <path d="M484.94 0.824219V6.82422" stroke="black" />
      <path d="M506.94 0.824219V6.82422" stroke="black" />
      <path d="M528.94 0.824219V18.3242" stroke="black" />
    </>
  );
  return (
    <svg
      width="516"
      height="19"
      viewBox="0 0 516 19"
      fill="none"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      {selectedUnit === "inches" ? inchesSVG : centimetersSVG}
    </svg>
  );
};

export const LargeRuler: React.FC<
  React.SVGProps<SVGSVGElement> & {
    selectedUnit?: string;
  }
> = props => {
  const { selectedUnit = "inches", ...restProps } = props;
  const centimetersSVG = (
    <>
      <path d="M1.20117 0.984375V18.4844" stroke="black" />
      <path d="M9.771 0.984375V6.98438" stroke="black" />
      <path d="M18.3413 0.984375V6.98438" stroke="black" />
      <path d="M26.9111 0.984375V6.98438" stroke="black" />
      <path d="M35.481 0.984375V18.4844" stroke="black" />
      <path d="M44.0513 0.984375V6.98438" stroke="black" />
      <path d="M52.6211 0.984375V6.98438" stroke="black" />
      <path d="M61.1912 0.984375V6.98438" stroke="black" />
      <path d="M69.7612 0.984375V18.4844" stroke="black" />
      <path d="M78.3311 0.984375V6.98438" stroke="black" />
      <path d="M86.9011 0.984375V6.98438" stroke="black" />
      <path d="M95.4712 0.984375V6.98438" stroke="black" />
      <path d="M104.041 0.984375V18.4844" stroke="black" />
      <path d="M112.611 0.984375V6.98438" stroke="black" />
      <path d="M121.181 0.984375V6.98438" stroke="black" />
      <path d="M129.751 0.984375V6.98438" stroke="black" />
      <path d="M138.321 0.984375V18.4844" stroke="black" />
      <path d="M146.891 0.984375V6.98438" stroke="black" />
      <path d="M155.461 0.984375V6.98438" stroke="black" />
      <path d="M164.031 0.984375V6.98438" stroke="black" />
      <path d="M172.601 0.984375V18.4844" stroke="black" />
      <path d="M181.171 0.984375V6.98438" stroke="black" />
      <path d="M189.741 0.984375V6.98438" stroke="black" />
      <path d="M198.311 0.984375V6.98438" stroke="black" />
      <path d="M206.881 0.984375V18.4844" stroke="black" />
      <path d="M215.451 0.984375V6.98438" stroke="black" />
      <path d="M224.021 0.984375V6.98438" stroke="black" />
      <path d="M232.591 0.984375V6.98438" stroke="black" />
      <path d="M241.161 0.984375V18.4844" stroke="black" />
      <path d="M249.731 0.984375V6.98438" stroke="black" />
      <path d="M258.301 0.984375V6.98438" stroke="black" />
      <path d="M266.871 0.984375V6.98438" stroke="black" />
      <path d="M275.441 0.984375V18.4844" stroke="black" />
      <path d="M284.011 0.984375V6.98438" stroke="black" />
      <path d="M292.581 0.984375V6.98438" stroke="black" />
      <path d="M301.151 0.984375V6.98438" stroke="black" />
      <path d="M309.721 0.984375V18.4844" stroke="black" />
      <path d="M318.291 0.984375V6.98438" stroke="black" />
      <path d="M326.861 0.984375V6.98438" stroke="black" />
      <path d="M335.431 0.984375V6.98438" stroke="black" />
      <path d="M344.001 0.984375V18.4844" stroke="black" />
      <path d="M352.571 0.984375V6.98438" stroke="black" />
      <path d="M361.141 0.984375V6.98438" stroke="black" />
      <path d="M369.711 0.984375V6.98438" stroke="black" />
      <path d="M378.281 0.984375V18.4844" stroke="black" />
      <path d="M386.851 0.984375V6.98438" stroke="black" />
      <path d="M395.421 0.984375V6.98438" stroke="black" />
      <path d="M403.991 0.984375V6.98438" stroke="black" />
      <path d="M412.562 0.984375V18.4844" stroke="black" />
      <path d="M421.131 0.984375V6.98438" stroke="black" />
      <path d="M429.701 0.984375V6.98438" stroke="black" />
      <path d="M438.271 0.984375V6.98438" stroke="black" />
      <path d="M446.841 0.984375V18.4844" stroke="black" />
      <path d="M455.411 0.984375V6.98438" stroke="black" />
      <path d="M463.981 0.984375V6.98438" stroke="black" />
      <path d="M472.552 0.984375V6.98438" stroke="black" />
      <path d="M481.122 0.984375V18.4844" stroke="black" />
      <path d="M489.691 0.984375V6.98438" stroke="black" />
      <path d="M498.261 0.984375V6.98438" stroke="black" />
      <path d="M506.832 0.984375V6.98438" stroke="black" />
      <path d="M515.401 0.984375V18.4844" stroke="black" />
      <path d="M523.971 0.984375V6.98438" stroke="black" />
      <path d="M532.542 0.984375V6.98438" stroke="black" />
      <path d="M541.112 0.984375V6.98438" stroke="black" />
      <path d="M549.682 0.984375V18.4844" stroke="black" />
      <path d="M558.251 0.984375V6.98438" stroke="black" />
      <path d="M566.822 0.984375V6.98438" stroke="black" />
      <path d="M575.392 0.984375V6.98438" stroke="black" />
      <path d="M583.961 0.984375V18.4844" stroke="black" />
      <path d="M592.531 0.984375V6.98438" stroke="black" />
      <path d="M601.102 0.984375V6.98438" stroke="black" />
      <path d="M609.672 0.984375V6.98438" stroke="black" />
      <path d="M618.242 0.984375V18.4844" stroke="black" />
      <path d="M626.812 0.984375V6.98438" stroke="black" />
      <path d="M635.382 0.984375V6.98438" stroke="black" />
      <path d="M643.952 0.984375V6.98438" stroke="black" />
      <path d="M652.521 0.984375V18.4844" stroke="black" />
      <path d="M661.092 0.984375V6.98438" stroke="black" />
      <path d="M669.662 0.984375V6.98438" stroke="black" />
      <path d="M678.232 0.984375V6.98438" stroke="black" />
      <path d="M686.802 0.984375V18.4844" stroke="black" />
      <path d="M695.372 0.984375V6.98438" stroke="black" />
      <path d="M703.942 0.984375V6.98438" stroke="black" />
      <path d="M712.512 0.984375V6.98438" stroke="black" />
      <path d="M721.082 0.984375V18.4844" stroke="black" />
      <path d="M729.652 0.984375V6.98438" stroke="black" />
      <path d="M738.222 0.984375V6.98438" stroke="black" />
      <path d="M746.792 0.984375V6.98438" stroke="black" />
      <path d="M755.362 0.984375V18.4844" stroke="black" />
      <path d="M763.932 0.984375V6.98438" stroke="black" />
      <path d="M772.502 0.984375V6.98438" stroke="black" />
      <path d="M781.072 0.984375V6.98438" stroke="black" />
      <path d="M789.642 0.984375V18.4844" stroke="black" />
      <path d="M798.212 0.984375V6.98438" stroke="black" />
      <path d="M806.782 0.984375V6.98438" stroke="black" />
      <path d="M815.352 0.984375V6.98438" stroke="black" />
      <path d="M823.922 0.984375V18.4844" stroke="black" />
      <path d="M832.492 0.984375V6.98438" stroke="black" />
      <path d="M841.062 0.984375V6.98438" stroke="black" />
      <path d="M849.632 0.984375V6.98438" stroke="black" />
      <path d="M858.202 0.984375V18.4844" stroke="black" />
      <path d="M866.772 0.984375V6.98438" stroke="black" />
      <path d="M875.342 0.984375V6.98438" stroke="black" />
      <path d="M883.912 0.984375V6.98438" stroke="black" />
      <path d="M892.482 0.984375V18.4844" stroke="black" />
      <path d="M901.052 0.984375V6.98438" stroke="black" />
      <path d="M909.622 0.984375V6.98438" stroke="black" />
      <path d="M918.192 0.984375V6.98438" stroke="black" />
      <path d="M926.762 0.984375V18.4844" stroke="black" />
      <path d="M935.332 0.984375V6.98438" stroke="black" />
      <path d="M943.902 0.984375V6.98438" stroke="black" />
      <path d="M952.472 0.984375V6.98438" stroke="black" />
      <path d="M961.042 0.984375V18.4844" stroke="black" />
      <path d="M969.612 0.984375V6.98438" stroke="black" />
      <path d="M978.182 0.984375V6.98438" stroke="black" />
      <path d="M986.752 0.984375V6.98438" stroke="black" />
      <path d="M995.322 0.984375V18.4844" stroke="black" />
      <path d="M1003.89 0.984375V6.98438" stroke="black" />
      <path d="M1012.46 0.984375V6.98438" stroke="black" />
      <path d="M1021.03 0.984375V6.98438" stroke="black" />
      <path d="M1029.6 0.984375V18.4844" stroke="black" />
      <path d="M1038.17 0.984375V6.98438" stroke="black" />
      <path d="M1046.74 0.984375V6.98438" stroke="black" />
      <path d="M1055.31 0.984375V6.98438" stroke="black" />
    </>
  );
  const inchesSVG = (
    <>
      <path d="M1.20117 0.984375V18.4844" stroke="black" />
      <path d="M23.2012 0.984375V6.98438" stroke="black" />
      <path d="M45.2012 0.984375V6.98438" stroke="black" />
      <path d="M67.2012 0.984375V6.98438" stroke="black" />
      <path d="M89.2012 0.984375V18.4844" stroke="black" />
      <path d="M111.201 0.984375V6.98438" stroke="black" />
      <path d="M133.201 0.984375V6.98438" stroke="black" />
      <path d="M155.201 0.984375V6.98438" stroke="black" />
      <path d="M177.201 0.984375V18.4844" stroke="black" />
      <path d="M199.201 0.984375V6.98438" stroke="black" />
      <path d="M221.201 0.984375V6.98438" stroke="black" />
      <path d="M243.201 0.984375V6.98438" stroke="black" />
      <path d="M265.201 0.984375V18.4844" stroke="black" />
      <path d="M287.201 0.984375V6.98438" stroke="black" />
      <path d="M309.201 0.984375V6.98438" stroke="black" />
      <path d="M331.201 0.984375V6.98438" stroke="black" />
      <path d="M353.201 0.984375V18.4844" stroke="black" />
      <path d="M375.201 0.984375V6.98438" stroke="black" />
      <path d="M397.201 0.984375V6.98438" stroke="black" />
      <path d="M419.201 0.984375V6.98438" stroke="black" />
      <path d="M441.201 0.984375V18.4844" stroke="black" />
      <path d="M463.201 0.984375V6.98438" stroke="black" />
      <path d="M485.201 0.984375V6.98438" stroke="black" />
      <path d="M507.201 0.984375V6.98438" stroke="black" />
      <path d="M529.201 0.984375V18.4844" stroke="black" />
      <path d="M551.201 0.984375V6.98438" stroke="black" />
      <path d="M573.201 0.984375V6.98438" stroke="black" />
      <path d="M595.201 0.984375V6.98438" stroke="black" />
      <path d="M617.201 0.984375V18.4844" stroke="black" />
      <path d="M639.201 0.984375V6.98438" stroke="black" />
      <path d="M661.201 0.984375V6.98438" stroke="black" />
      <path d="M683.201 0.984375V6.98438" stroke="black" />
      <path d="M705.201 0.984375V18.4844" stroke="black" />
      <path d="M727.201 0.984375V6.98438" stroke="black" />
      <path d="M749.201 0.984375V6.98438" stroke="black" />
      <path d="M771.201 0.984375V6.98438" stroke="black" />
      <path d="M793.201 0.984375V18.4844" stroke="black" />
      <path d="M815.201 0.984375V6.98438" stroke="black" />
      <path d="M837.201 0.984375V6.98438" stroke="black" />
      <path d="M859.201 0.984375V6.98438" stroke="black" />
      <path d="M881.201 0.984375V18.4844" stroke="black" />
      <path d="M903.201 0.984375V6.98438" stroke="black" />
      <path d="M925.201 0.984375V6.98438" stroke="black" />
      <path d="M947.201 0.984375V6.98438" stroke="black" />
      <path d="M969.201 0.984375V18.4844" stroke="black" />
      <path d="M991.201 0.984375V6.98438" stroke="black" />
      <path d="M1013.2 0.984375V6.98438" stroke="black" />
      <path d="M1035.2 0.984375V6.98438" stroke="black" />
      <path d="M1057.2 0.984375V18.4844" stroke="black" />
    </>
  );
  return (
    <svg
      width="1058"
      height="36"
      viewBox="0 0 1058 36"
      fill="none"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      {selectedUnit === "inches" ? inchesSVG : centimetersSVG}
    </svg>
  );
};
