import React from "react";
import clsx from "clsx";
import PriceRange from "components/PDPCTA/components/PriceRange";
import OrderDetails from "components/PDPCTA/components/OrderDetails";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

interface SwatchOverlayProps {
  swatch: any;
  isSelected: boolean;
  isUnavailable: boolean;
  handleShowInfo: () => void;
  isEnlarge: boolean;
  onClose?: () => void;
  productConfigData?: any;
}

const SwatchOverlay: React.FC<SwatchOverlayProps> = ({
  swatch,
  isSelected,
  isUnavailable,
  handleShowInfo,
  isEnlarge,
  productConfigData
}) => {
  const { pageContent } = usePageContent();
  const commonOrderDetailsStyle =
    "text-[10px] leading-[120%] font-primary-rhlight tracking-normal font-light";

  return isEnlarge ? (
    <div
      className={clsx(
        `absolute w-full h-full opacity-0 hover:opacity-100 cursor-pointer top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col px-3 justify-center items-center gap-1 `,
        { "!opacity-100": isSelected }
      )}
    >
      <div
        className={clsx(
          "py-6 px-2 bg-[rgba(255,255,255,0.60)] shadow-[0_0_8px_0_rgba(0,0,0,0.25)] w-[calc(100%-24px)] flex flex-col gap-6 absolute",
          isSelected ? "bottom-3" : "bottom-[168px]"
        )}
      >
        <div className="flex flex-col">
          <h6
            className={clsx(
              "m-0 text-xs capitalize font-primary-rhlight text-center font-light leading-[120%] mix-blend-difference text-white tracking-normal whitespace-normal",
              isUnavailable && "line-through"
            )}
          >
            {swatch?.swatchGroupName}
          </h6>
          <h5
            className={clsx(
              "text-xs leading-[120%] uppercase font-primary-rhlight font-light tracking-normal mt-[6px] mix-blend-difference text-white text-center",
              isSelected && "mb-2"
            )}
          >
            {swatch?.title}
          </h5>
          {isSelected && (
            <p
              onClick={handleShowInfo}
              className="text-[10px] leading-[120%] font-primary-rhlight tracking-normal font-light text-center mix-blend-difference text-white underline underline-offset-2 uppercase"
            >
              {pageContent?.view_fabric_details}
            </p>
          )}
        </div>
        {isSelected && (
          <>
            <div className="flex flex-col gap-2">
              <p className="text-xs capitalize font-primary-rhlight tracking-normal leading-[120%] mix-blend-difference text-white px-2 font-light text-center whitespace-normal">
                {productConfigData?.selectedOptsString}
              </p>
              <PriceRange
                memberPrice={productConfigData?.memberPrice}
                regularPrice={productConfigData?.regularPrice}
                hideDivider={true}
                className={"flex-col !items-center"}
                memberPriceClassName={
                  "!font-normal !font-primary-rhroman text-center  !text-xs !leading-[120%]  mix-blend-difference !text-white capitalize tracking-normal"
                }
                regularPriceClassName={
                  "!font-normal !font-primary-rhthin text-center !text-xs leading-[120%] mix-blend-difference !text-white capitalize tracking-normal"
                }
              />
            </div>
            <div className="flex flex-col">
              <OrderDetails
                deliveryDate={productConfigData?.deliveryDate || "dec 23"}
                zipCode={productConfigData?.postalCode}
                orderType={productConfigData?.orderType || "dropship"}
                prefix={`${pageContent?.Delivered_by} <br/>`}
                className="text-center leading-[12px] px-3 font-light whitespace-normal"
                customOrderClassName={`inline ${commonOrderDetailsStyle} mix-blend-difference text-white`}
                deliveryDateClassName={`inline ${commonOrderDetailsStyle} mix-blend-difference text-white`}
                zipCodeClassName={`inline ${commonOrderDetailsStyle} mix-blend-difference text-white`}
              />
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        `absolute w-full h-full opacity-0 cursor-pointer hover:opacity-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col px-1.5 justify-center items-center gap-1 mix-blend-difference text-white`,
        { "!opacity-100": isSelected }
      )}
    >
      <h6
        className={clsx(
          "m-0 text-xs capitalize font-primary-rhlight text-center font-light leading-[100%] tracking-normal whitespace-normal",
          isUnavailable && "line-through"
        )}
      >
        {swatch?.swatchGroupName}
      </h6>

      <span
        className={clsx(
          "text-xs uppercase font-primary-rhthin font-thin text-center leading-[100%] tracking-normal",
          isUnavailable && "line-through"
        )}
      >
        {swatch?.title}
      </span>
    </div>
  );
};

export default SwatchOverlay;
