import React, { useState } from "react";
import { LargeRuler, SmallRuler } from "./RulerSVGs";
import clsx from "clsx";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

interface MeasurementToggleProps {
  initialUnit?: "inches" | "centimeters";
  variant?: "small" | "large";
  onUnitChange?: (unit: "inches" | "centimeters") => void;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  subContainerClassName?: string;
  inchesClassName?: string;
  inchesStyle?: React.CSSProperties;
  centimetersClassName?: string;
  centimetersStyle?: React.CSSProperties;
  rulerProps?: React.SVGProps<SVGSVGElement>;
}

const MeasurementToggle: React.FC<MeasurementToggleProps> = ({
  initialUnit = "inches",
  variant = "small",
  onUnitChange,
  containerClassName = "",
  containerStyle,
  subContainerClassName = "",
  rulerProps,
  inchesClassName,
  inchesStyle,
  centimetersClassName,
  centimetersStyle
}) => {
  const [selectedUnit, setSelectedUnit] = useState<"inches" | "centimeters">(
    initialUnit
  );
  const { pageContent } = usePageContent();

  const handleUnitChange = (unit: "inches" | "centimeters") => {
    setSelectedUnit(unit);
    onUnitChange?.(unit);
  };

  return (
    <div
      id={"component-pdp-swatch-carousel_measurement_toggle"}
      data-testid={"component-pdp-swatch-carousel_measurement_toggle"}
      className={clsx(
        `flex gap-[29.5px] items-center justify-between pl-4 bg-white`,
        containerClassName
      )}
      style={containerStyle}
    >
      <div
        className={clsx(
          "flex flex-col gap-[2px] my-[12.5px]",
          subContainerClassName
        )}
      >
        <span
          onClick={() => handleUnitChange("inches")}
          style={inchesStyle}
          className={clsx(
            "uppercase  cursor-pointer",
            selectedUnit === "inches"
              ? "underline underline-offset-2 font-primary-rhlight"
              : "font-primary-rhthin",
            inchesClassName
          )}
        >
          {pageContent?.INCHES}
        </span>
        <span
          onClick={() => handleUnitChange("centimeters")}
          style={centimetersStyle}
          className={clsx(
            "uppercase  cursor-pointer",
            selectedUnit === "centimeters"
              ? "underline underline-offset-2 font-primary-rhlight"
              : "font-primary-rhthin",
            centimetersClassName
          )}
        >
          {pageContent?.Centimeters}
        </span>
      </div>
      {variant === "small" ? (
        <SmallRuler {...rulerProps} selectedUnit={selectedUnit} />
      ) : (
        <LargeRuler {...rulerProps} selectedUnit={selectedUnit} />
      )}
    </div>
  );
};

export default MeasurementToggle;
