import React from "react";
import { formatDateParts } from "../helper";
import { DeliveredByProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const DeliveredBy: React.FC<DeliveredByProps> = ({
  deliveryDate,
  prefix = "",
  className,
  suffix
}) => {
  const {
    month,
    day,
    suffix: daySuffix
  } = formatDateParts(new Date(deliveryDate));
  const { pageContent } = usePageContent();

  return (
    <span
      id={"component-pdp-cta_delivered_by"}
      data-testid={"component-pdp-cta_delivered_by"}
      className={className}
      aria-label={pageContent?.Delivery_info}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: prefix || pageContent?.Delivered_by
        }}
      />
      &nbsp;{month} {day}
      <sup className="lowercase leading-[100%] tracking-normal">
        {daySuffix}
      </sup>{" "}
      <span className="normal-case">{suffix || pageContent?.TO}</span>
    </span>
  );
};

export default memoize(DeliveredBy);
