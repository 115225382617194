import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import SwatchDetailPanel from "./SwatchDetailPanel";
import SwatchCarousel from "./SwatchCarousel";
import { usePDPSwatchCarouselData } from "./usePDPSwatchCarouselData";
import { PDPProductDetailsProps } from "./types";
import { TailwindTypography } from "components/TailwindTypography";
import isEmpty from "lodash.isempty";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const PDPSwatchCarousel: React.FC<PDPProductDetailsProps> = props => {
  const {
    carouselRef,
    currentSwatches,
    currentSelectedSwatch,
    SwatchDetail,
    itemsToShow,
    height,
    width,
    largeWidth,
    largeHeight,
    isEnlarge,
    enlargedSwatchSelected,
    productConfigData,
    setEnlarge,
    endRef,
    handleMouseUp,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleImageDragStart,
    handleImageClick,
    setSelectedMaterialName,
    getBorderClass,
    handleShowInfo,
    setSwatchDetail,
    handleScrollLeft,
    handleScrollRight,
    handleEnlargeClick
  } = usePDPSwatchCarouselData(props);

  const { pageContent } = usePageContent();

  return currentSwatches?.swatches?.length ? (
    <div
      className={!isEnlarge ? "relative" : ""}
      id={"component-pdp-swatch-carousel"}
      data-testid={"component-pdp-swatch-carousel"}
    >
      {!SwatchDetail && (
        <TailwindTypography
          onClick={handleEnlargeClick}
          className="underline cursor-pointer my-2 text-[11px] px-10 underline-offset-2 font-primary-rhthin uppercase tracking-normal leading-[100%]"
        >
          {pageContent?.Enlarge_fabrics}
        </TailwindTypography>
      )}

      <SwatchCarousel
        swatches={currentSwatches?.swatches as ProductSwatch[]}
        currentSelectedSwatch={currentSelectedSwatch}
        itemsToShow={itemsToShow}
        height={height}
        width={width}
        largeWidth={largeWidth}
        largeHeight={largeHeight}
        SwatchDetail={SwatchDetail}
        carouselRef={carouselRef}
        isEnlarge={isEnlarge}
        onClose={
          isEnlarge
            ? () => {
                setEnlarge(false);
                if (!isEmpty(enlargedSwatchSelected)) {
                  setSwatchDetail(enlargedSwatchSelected);
                }
              }
            : undefined
        }
        endRef={endRef}
        handleImageClick={handleImageClick}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        handleMouseLeave={handleMouseLeave}
        handleMouseMove={handleMouseMove}
        handleImageDragStart={handleImageDragStart}
        handleShowInfo={handleShowInfo}
        handleScrollLeft={handleScrollLeft}
        handleScrollRight={handleScrollRight}
        productConfigData={productConfigData}
      />

      {SwatchDetail && (
        <SwatchDetailPanel
          enlargedSwatchSelected={!!enlargedSwatchSelected}
          swatch={SwatchDetail}
          onClose={() => setSwatchDetail(null)}
          onApplySofa={() => {
            if (!isEmpty(enlargedSwatchSelected)) {
              setSwatchDetail(null);
              setEnlarge(false);
              setSwatchDetail(enlargedSwatchSelected);
            }
          }}
          isEnlarge={isEnlarge}
          productConfigData={productConfigData}
        />
      )}
    </div>
  ) : null;
};

export default memoize(PDPSwatchCarousel);
