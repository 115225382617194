import React, { Fragment } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { PreviousArrowIcon, NextArrowIcon } from "./SvgArrowsIcon";
import clsx from "clsx";

const Arrows = ({
  scrollPrev,
  scrollNext,
  prevClassName = "",
  nextClassName = ""
}) => {
  return (
    <Fragment>
      <button
        className={clsx(
          "embla__prev m-0 p-0 flex w-10 justify-center items-center absolute bg-transparent border-0 cursor-pointer left-0",
          prevClassName
        )}
        onClick={scrollPrev}
        aria-label="previous"
      >
        <PreviousArrowIcon className="mx-auto" />
      </button>
      <button
        className={clsx(
          "embla__next m-0 p-0 flex w-10 justify-center items-center  absolute bg-transparent border-0 cursor-pointer right-0",
          nextClassName
        )}
        onClick={scrollNext}
        aria-label="next"
      >
        <NextArrowIcon className="mx-auto" />
      </button>
    </Fragment>
  );
};

export default memoize(Arrows);
