import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import CloseIcon from "./CloseIcon";
import { SwatchDetailPanelProps } from "./types";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import ExpandableText from "./ExpandableText";
import clsx from "clsx";
import PriceRange from "@RHCommerceDev/component-pdp-cta/components/PriceRange";
import OrderDetails from "@RHCommerceDev/component-pdp-cta/components/OrderDetails";
import MeasurementToggle from "./MeasurementToggle";

const SwatchDetailPanel: React.FC<SwatchDetailPanelProps> = ({
  swatch,
  onClose,
  isEnlarge,
  onApplySofa,
  enlargedSwatchSelected = false,
  productConfigData
}) => {
  if (!swatch) return null;
  const { pageContent } = usePageContent();
  const contentStyle =
    "mix-blend-difference text-white text-xs tracking-normal leading-[120%] font-primary-rhthin font-light [&>strong]:text-[30px] [&>strong]:font-primary-ultra-thin [&>strong]:font-[75]  [&>strong]:leading-[90%] [&>strong]:uppercase [&>strong]:-tracking-[0.03em]";
  return (
    <div
      className={
        isEnlarge
          ? "absolute top-0 w-full h-[calc(100vh-195px)] z-50"
          : "min-h-[158px] w-full relative px-10"
      }
      id={"component-pdp-swatch-carousel_swatch_detail_panel"}
      data-testid={"component-pdp-swatch-carousel_swatch_detail_panel"}
    >
      <div
        className={`relative h-full w-full flex  flex-col flex-1 gap-5
          ${isEnlarge ? "justify-end" : "pt-4 pl-4"}`}
        style={{
          background: `url('${swatch?.swatchImageLg?.url}') top center / contain`
        }}
      >
        <div
          className={`flex  flex-col ${
            isEnlarge ? "pl-10 max-w-[33%] w-full gap-6" : "flex-1 gap-5"
          }`}
        >
          {!!swatch?.optionCopy ? (
            !isEnlarge ? (
              <ExpandableText
                content={swatch?.optionCopy}
                maxHeight={152}
                className={clsx(contentStyle, "max-w-[50%]")}
              />
            ) : (
              <div
                className={contentStyle}
                dangerouslySetInnerHTML={{
                  __html: `${swatch?.optionCopy}`
                }}
              />
            )
          ) : (
            <div className="flex-1 flex flex-col gap-6 ">
              <div className="flex flex-col gap-1.5">
                <div className="flex justify-between items-center">
                  <h3 className="m-0 mix-blend-difference  text-white text-[36px] font-primary-thin font-[75] leading-9 uppercase ">
                    {swatch?.swatchGroupName}
                  </h3>
                </div>
                <p className="m-0 mix-blend-difference text-white text-xs tracking-[0.48px] leading-[120%] font-light uppercase">
                  {swatch?.title}
                </p>
              </div>
            </div>
          )}
          <CloseIcon
            className=" mix-blend-difference text-white text-xl absolute top-0 right-0 cursor-pointer"
            onClick={onClose}
          />
          <div
            className={clsx(
              "flex justify-between  gap-4 w-full",
              isEnlarge ? "flex-col items-start" : "flex-row items-end"
            )}
          >
            <div className="flex flex-row w-full gap-2">
              {enlargedSwatchSelected && isEnlarge && (
                <button
                  className="flex max-w-[184px] h-[48px] p-0 items-center justify-center w-full bg-black text-white flex-start uppercase text-xs"
                  onClick={onApplySofa}
                >
                  {pageContent?.APPLY_TO_SOFA}
                </button>
              )}
              <button
                className={clsx(
                  "flex  p-0 items-center justify-center flex-nowrap  mix-blend-difference text-white w-full flex-start border border-solid uppercase text-xs",
                  isEnlarge
                    ? "max-w-[184px] h-[48px]"
                    : "max-w-[152px] h-[32px] mb-2"
                )}
              >
                {pageContent?.Order_Swatch}
              </button>
            </div>

            {isEnlarge && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col items-start">
                  <p className="text-xs tracking-normal leading-[120%] font-primary-rhthin font-light text-left whitespace-normal mix-blend-difference !text-white">
                    {productConfigData?.selectedOptsString}
                  </p>

                  <PriceRange
                    memberPrice={productConfigData?.memberPrice || 0}
                    regularPrice={productConfigData?.regularPrice || 0}
                    memberPriceClassName={
                      "!text-xs !font-primary-rhlight  !tracking-normal !leading-[120%] text-center capitalize mix-blend-difference !text-white"
                    }
                    regularPriceClassName={
                      "!text-xs !tracking-normal !leading-[120%] !font-primary-rhthin text-center capitalize mix-blend-difference !text-white"
                    }
                  />
                </div>
                <div className="flex flex-col items-start">
                  <OrderDetails
                    deliveryDate={productConfigData?.deliveryDate || "dec 23"}
                    zipCode={productConfigData?.postalCode || ""}
                    orderType={productConfigData?.orderType || "dropship"}
                    prefix={`${pageContent?.Delivered_by}`}
                    className="text-end leading-[12px] font-light whitespace-normal mix-blend-difference text-white"
                    customOrderClassName={
                      "inline !text-[10px] mix-blend-difference text-white"
                    }
                    deliveryDateClassName={
                      "inline !text-[10px] mix-blend-difference text-white"
                    }
                    zipCodeClassName={
                      "inline !text-[10px] mix-blend-difference text-white"
                    }
                  />
                </div>
              </div>
            )}

            {!isEnlarge && (
              <MeasurementToggle
                initialUnit="inches"
                variant="small"
                containerClassName={"flex-row-reverse !pl-0 !bg-white-30"}
                subContainerClassName={"px-4"}
                rulerProps={{ className: "mb-[34.5px]" }}
                centimetersClassName={
                  "text-[10px] leading-[120%] tracking-normal"
                }
                inchesClassName={"text-[10px] leading-[120%] tracking-normal"}
              />
            )}
          </div>
        </div>
        {isEnlarge && (
          <MeasurementToggle
            initialUnit="inches"
            variant="large"
            containerClassName={"!pl-10 w-full !bg-white-60"}
            subContainerClassName={"w-full max-w-[78px]"}
            rulerProps={{ className: "mb-[17px]" }}
            centimetersClassName={"text-[10px] leading-[120%] tracking-normal"}
            inchesClassName={"text-[10px] leading-[120%] tracking-normal"}
          />
        )}
      </div>
    </div>
  );
};

export default memoize(SwatchDetailPanel);
