import React from "react";
import CustomOrder from "./CustomOrder";
import DeliveredBy from "./DeliveredBy";
import ZipCode from "./ZipCode";
import { OrderDetailsProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import template from "lodash/template";

const OrderDetails: React.FC<OrderDetailsProps> = ({
  deliveryDate,
  zipCode,
  onCustomOrderClick,
  onZipCodeClick,
  className = "text-black text-15px capitalize font-primary-rhthin leading-[100%]",
  customOrderClassName,
  deliveryDateClassName,
  zipCodeClassName,
  orderType = "",
  prefix
}) => {
  const { pageContent } = usePageContent();
  return (
    <div
      id={"component-pdp-cta_order_details"}
      data-testid={"component-pdp-cta_order_details"}
      className={className}
      aria-label={template(pageContent?.Delivery_information)({
        deliveryDate,
        zipCode
      })}
    >
      <CustomOrder
        onClick={onCustomOrderClick}
        className={customOrderClassName}
        orderType={orderType}
      />{" "}
      <DeliveredBy
        deliveryDate={deliveryDate}
        className={deliveryDateClassName}
        prefix={prefix}
      />{" "}
      <ZipCode
        zipCode={zipCode}
        onClick={onZipCodeClick}
        className={zipCodeClassName}
      />
    </div>
  );
};

export default memoize(OrderDetails);
