import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import useParams, { useGetAllParams } from "@RHCommerceDev/hooks/useParams";
import { filterCustomRugOptionTypes } from "@RHCommerceDev/utils/customProductHelper";
import { isSSRToggledWithClientRender } from "@RHCommerceDev/utils/ssrHelpers";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import useMeasurementType from "@RHCommerceDev/hooks/useMeasurementType";
import { useCookies } from "@RHCommerceDev/hooks/useCookies";
import useSite from "@RHCommerceDev/hooks/useSite";
import useLocale from "@RHCommerceDev/hooks/useLocale/useLocale";
import {
  AddOnConfigModes,
  AddOnDataType
} from "@RHCommerceDev/component-product-addon-checkbox";
import { countryCurrencyMapper } from "@RHCommerceDev/resources/countries-config.json";
import {
  querGetSku,
  queryLineItemLite,
  queryLineItemSku,
  queryMultiSkuComponentsV2,
  queryLineItemLiteV3
} from "@RHCommerceDev/graphql-client-product";
import { getCountryCodeFromPostalCodeAndSiteId } from "@RHCommerceDev/utils/postalcode-validation";
import {
  getCustomProductOptions,
  setFeetAsDecimal,
  getFullSkuIdFromRelatedProductCardData,
  createAddOnProductSet,
  checkIsAllCustomOptionsSelected,
  checkIsAddOnProductAndPreselected,
  checkAddOnProductDoesNotExistsInAddonItems,
  getSelectedOptions,
  getColorPreselectedAndSelectedOptions,
  checkAllOptionSelectedOrIsColorPreSelected,
  checkIsMultiSkuProduct,
  checkIsMultiSkuProductFullyConfigured,
  createRoundCustomProductConfig,
  createRectangleCustomProductConfig,
  checkOptionExistsInParentSelectedOptions,
  getProductPageUrlPathName,
  createInitialRefetchOptions,
  checkDisplayAddOns
} from "@RHCommerceDev/component-related-product-card/helper";
import useDidMountEffect from "@RHCommerceDev/hooks/useDidMountEffect";
import { useProductImage } from "@RHCommerceDev/hooks/useProductImage";
import { useQuantity } from "@RHCommerceDev/hooks/useQuantity";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { MonogramOrderProps } from "@RHCommerceDev/page-monogram-dialog";
import {
  MONOGRAM_ORDER_DEFAULT,
  SALE_PERCENT_100,
  SKIP_CALL_USERTYPE
} from "@RHCommerceDev/utils/constants";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import { isEmpty } from "lodash";
import { defaultRideAlongData } from "@RHCommerceDev/page-product/constants";
import { multiSkuPriceMapper } from "@RHCommerceDev/component-price-range-display/utils/convertFusionPriceToRange";
import { cleanMonogramOrder } from "@RHCommerceDev/utils/monogramUtils";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { addOnDataType } from "@RHCommerceDev/component-product-addon/types";
import { getMemberSavingsText } from "@RHCommerceDev/utils/memberSavingsTextUtils";
import { useFetchParams } from "@RHCommerceDev/hooks/useFetchParams/useFetchParams";
import { useCustomLocation } from "@RHCommerceDev/hooks/useCustomLocation";
import { useRelatedProductCardAnalytics } from "@RHCommerceDev/component-related-product-card/useRelatedProductCardAnalytics";
import {
  RefetchOptionsType,
  RelatedProductCardProps
} from "@RHCommerceDev/component-related-product-card/types";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

const DEFAULT_MAX_QUANTITY = 40;

export const useRelatedProductCardDataV2 = (
  props: RelatedProductCardProps & {
    setProductSize: React.Dispatch<React.SetStateAction<string>>;
  }
) => {
  const {
    fullSkuId,
    selectedSwatches,
    postalCode,
    saleContextFilter,
    changeSwatchImageFromLineItem,
    handleAddAllItemToCart,
    handleRemoveItemFromAddAll,
    lineItemIndex,
    onOptionsSelected,
    isShellProduct,
    rideAlongData,
    setRideAlongData,
    authenticated,
    inStockReady,
    selectedRugTrimSwatchId,
    selectedRugTrimSwatchIds,
    spoOverrideMessage,
    setProductAddonItems,
    setPrintFullSkuId,
    handleOnSwatchClick,
    allSwatchGroups,
    parentChosenLineItemOptions,
    parentChosenLineItemOptionIds,
    heroImageLevel,
    swatchGroups,
    setPreConfiguredSku,
    setProductSize
  } = props;

  const { triggerAddToWishlistAnalyticsEvent } =
    useRelatedProductCardAnalytics();

  const selectedSize = useRef<string | null>(null);
  const selectedIds = useRef<ProductAvailableOption[]>([]);

  const env = useEnv();
  const siteId = useSite();
  const locale = useLocale();
  const brand = useSite();
  const country = useCountry();
  const client = useApolloClient();
  const { userType } = useRhUserAtomValue();
  const { isConcierge } = useAppId();
  const measureSystem = useMeasurementType();
  const { pageContent } = usePageContent();

  const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
  const addAllToCartFlag = yn(env.FEATURE_PDP_ADD_ALL);
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);

  const { pathname } = useCustomLocation();

  const [cookies] = useCookies(["pc"]);

  const data = useMemo(() => {
    return {
      ...props?.data,
      productLineItem: filterCustomRugOptionTypes(props?.data?.productLineItem)
    };
  }, [props?.data]);

  const params = useParams({
    productId: "",
    fullSkuId: "",
    prevFullSkuId: "",
    color: "",
    sale: ""
  });

  const _params = useFetchParams(
    pathname,
    getProductPageUrlPathName(yn(env.FEATURE_URL_CHANGE))
  );

  let [productId] = _params;

  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }

  const queryParams = useGetAllParams();

  const currencyCode = countryCurrencyMapper[country];

  const _fullSkuId = getFullSkuIdFromRelatedProductCardData({
    data,
    fullSkuId,
    params,
    pathname
  });

  const isRideAlongProduct = useMemo(
    () => rideAlongData?.casingProduct === data.id,
    [rideAlongData?.casingProduct, data.id]
  );

  // customOptions must default to this?
  const [customOptions, setCustomOptions] = useState({
    diameter: { feet: "", inches: "" },
    width: { feet: "", inches: "" },
    length: { feet: "", inches: "" }
  });

  const [loading, setLoading] = useState(!isSSRToggledWithClientRender());

  const [skuLoading, setSkuLoading] = useState(!isSSRToggledWithClientRender());
  const [refetchOptions, setRefetchOptions] = useState<
    RefetchOptionsType & { unavailableOption?: string[] }
  >(
    createInitialRefetchOptions(
      data,
      data?.id === params?.productId,
      _fullSkuId,
      postalCode,
      saleContextFilter
    )
  );

  const [lineItem, setLineItem] = useState(
    filterCustomRugOptionTypes(data?.productLineItem)
  );

  const [swatchOptionStatus, setSwatchOptionStatus] = useState<
    { [key: string]: string }[]
  >([]);

  const [isMonogrammed, setIsMonogrammed] = useState(false);
  const [lineItemSku, setLineItemSku] = useState(null);
  const [skuDetails, setSkuDetails] = useState<GetSkuResponse>({
    fullSkuId: null,
    components: []
  });
  const [addOnsData, setAddOnsData] = useState<AddOnDataType[]>([]);
  const [addOnProduct, setAddOnProduct] = useState(null);
  const [panelProdConfigurationData, setPanelProdConfigurationData] =
    useState<Maybe<addOnDataType>>(null);
  const [multiSkuComponentsDataV2, setMultiSkuComponentsDataV2] =
    useState<RevisedMultiSkuComponentsResponse>(
      {} as RevisedMultiSkuComponentsResponse
    );
  const [qty, setQty] = useState(
    env.FEATURE_PDP_DEFAULT_QUANTITY ? PDP_DEFAULT_QTY : 1
  );
  const [panelProdData, setPanelProdData] =
    useState<Maybe<ProductAddonsInfo>>(null);

  let [selectedOptions, setSelectedOptions] = useState<
    ProductAvailableOption[]
  >([]);
  const [giftCardFrom, setGiftCardFrom] = useState<string>("");
  const [giftCardTo, setGiftCardTo] = useState<string>("");
  const [rugTrim, setRugTrim] = useState<string>("");
  const [selectedAddOnProduct, setSelectedAddOnProduct] =
    useState<Maybe<addOnDataType>>(null);
  const [isAllCustomOptionSelected, setIsAllCustomOptionSelected] =
    useState(false);
  const [isCustomOptionSelectionValid, setIsCustomOptionSelectionValid] =
    useState(false);
  const [postalCodeDialog, setPostalCodeDialog] = useState(false);
  const [surchargeInfoDialog, setSurchargeInfoDialog] = useState(false);
  const [cartDialog, setCartDialog] = useState(false);
  const [wishlistDialog, setWishlistDialog] = useState(false);
  const [giftRegistryDialog, setGiftRegistryDialog] = useState(false);
  const [monogramDialog, setMonogramDialog] = useState(false);
  const [monogramOrder, setMonogramOrder] = useState<MonogramOrderProps>(
    MONOGRAM_ORDER_DEFAULT
  );

  //this is responsible for add on products add to cart data
  const [addonDataAddToCart, setAddonDataAddToCart] = useState([]);
  const [isQtyChanged, setIsQtyChanged] = useState(false);
  const [giftCardValueExceed, setGiftCardValueExceed] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isLineItemImageUrlFailed, setIsLineItemImageUrlFailed] =
    useState<boolean>(false);
  const [addonDataAddToProject, setAddonDataAddToProject] = useState([]);
  const [
    selectedAddOnsNotFullyConfigured,
    setSelectedAddOnsNotFullyConfigured
  ] = useState<boolean>(false);

  const [currentUserType, setCurrentUserType] = useState(userType);

  useDidMountEffect(() => {
    if (
      !SKIP_CALL_USERTYPE?.includes(userType?.toLowerCase()!) &&
      yn(env.FEATURE_SKIP_PAGE_RELOAD)
    ) {
      setCurrentUserType(userType);
    }
  }, [userType]);

  const { refetchLineItemImage: refetchAddOnLineItemImage } = useProductImage({
    productId: "",
    options: [],
    skip: true
  });

  const { refetchQty: addOnQtyfetch } = useQuantity({
    country,
    sku: "SKU_MISSING_UI",
    skip: true
  });

  const updateProductAddonItems = (
    prev: addOnDataType[],
    addOnProduct: AddOnDataType
  ): AddOnDataType[] => {
    let updatedItems: AddOnDataType[] = [];

    prev?.forEach((item: AddOnDataType) => {
      updatedItems?.push({
        ...item,
        ...(checkIsAddOnProductAndPreselected(addOnProduct, item)
          ? {
              qty: addOnProduct?.qty,
              productSelected: addOnProduct?.productSelected,
              pricing: addOnProduct?.pricing,
              preBillMessage: addOnProduct?.preBillMessage,
              fullSkuId: addOnProduct?.fullSkuId,
              spo: addOnProduct?.spo,
              maxQuantity: addOnProduct?.maxQuantity,
              mainItemProductId: addOnProduct?.mainItemProductId,
              selectedOptions: addOnProduct?.selectedOptions
            }
          : {})
      });
    });

    if (checkAddOnProductDoesNotExistsInAddonItems(prev, addOnProduct)) {
      updatedItems.push(addOnProduct);
    }

    return updatedItems;
  };

  useEffect(() => {
    setProductAddonItems?.(prev => {
      if (addOnProduct) {
        return updateProductAddonItems(prev, addOnProduct);
      }
      return prev;
    });
  }, [addOnProduct]);

  useEffect(() => {
    let optionIds = getSelectedOptions(lineItem, selectedSwatches, lineItem);

    setRefetchOptions?.({
      ...refetchOptions,
      fullSkuId: !optionIds?.length ? _fullSkuId : undefined,
      selectedOptionIds: optionIds?.length ? optionIds : undefined,
      shouldFetchSku: lineItem?.availableOptions?.length === optionIds?.length
    });
  }, [selectedSwatches]);

  const fetchLineItemWithRetry = async () => {
    let response = await lazyQueryLineItem(
      _fullSkuId
        ? {}
        : {
            fullSkuId: undefined
          }
    );
    if (!response) {
      response = await lazyQueryLineItem({
        fullSkuId: undefined
      });
    }

    return response;
  };

  useEffect(() => {
    if (isRideAlongProduct) {
      return;
    }

    async function fetchQuery() {
      let response = await fetchLineItemWithRetry();

      const customProductOptions = getCustomProductOptions({
        lineItem,
        customOptions
      });

      const { selectedOptions, isColorPreSelected } =
        getColorPreselectedAndSelectedOptions({
          queryParams,
          response,
          lineItem,
          selectedSwatches,
          allSwatchGroups,
          handleOnSwatchClick
        });

      const isAllOptionSelectedOrColorPreSelected =
        checkAllOptionSelectedOrIsColorPreSelected(
          selectedOptions,
          response?.availableOptions,
          isColorPreSelected
        );
      if (isAllOptionSelectedOrColorPreSelected) {
        const finalVariable = {
          fullSkuId: _fullSkuId || params?.fullSkuId,
          selectedOptionIds: selectedOptions,
          shouldFetchSku: isAllOptionSelectedOrColorPreSelected,
          qty: refetchOptions?.qty,
          ...customProductOptions
        };

        const responseLineItem = await lazyQueryLineItem(finalVariable);
        const responseSku = await lazyQueryLineItemSku(finalVariable);

        if (isColorPreSelected) {
          refetchLineItemImage({ selectedOptionIds: selectedOptions });
          response = { ...responseLineItem };
        } else {
          resolveSku(selectedOptions, customProductOptions, _fullSkuId);
          response = { ...responseLineItem, sku: responseSku };
        }
      }

      const selectedWidthOption = response?.availableOptions
        ?.find(opt => opt.type === "Width")
        ?.options?.find(opt => opt?.status === "selected");

      if (selectedWidthOption) {
        setProductSize(selectedWidthOption.value);
      }

      setLineItem(response);

      if (
        checkIsMultiSkuProductFullyConfigured(
          response,
          selectedOptions,
          data?.metaProduct
        )
      ) {
        const selectedOptionIds = selectedOptions?.map(optionSet =>
          optionSet?.toString()
        );
        getMultiSkuComponents(
          data?.id,
          selectedOptionIds,
          postalCode,
          refetchOptions?.qty
        );
      }
      setLoading(false);
    }
    fetchQuery();
  }, []);

  const lazyQueryGetSku = useCallback(
    async variables => {
      setSkuLoading(true);
      const {
        data: { getSku }
      } = await client.query({
        query: querGetSku,
        variables: {
          productId: data?.id,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          isCustom: data?.customProduct,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      setPrintFullSkuId?.(getSku?.fullSkuId || "");

      return getSku as GetSkuResponse;
    },
    [
      client,
      data?.id,
      _fullSkuId,
      data?.personalizeInfo?.monogrammable,
      data?.onSale,
      data?.customProduct,
      yn(env.FEATURE_SKIP_PAGE_RELOAD) ? currentUserType : userType,
      postalCode,
      currencyCode,
      params?.productId,
      saleContextFilter,
      siteId,
      measureSystem,
      saleContextFilter,
      internationalFlag,
      locale
    ]
  );

  const lazyQueryLineItem = useCallback(
    async variables => {
      setLoading(true);
      const {
        data: { productLineItem }
      } = await client.query({
        query: queryLineItemLite,

        variables: {
          productId: data?.id,
          fullSkuId: _fullSkuId,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku: !!(
            data?.priceRangeDisplay?.hasOnlyOneSku ||
            !data?.productLineItem?.availableOptions?.length
          ),
          nextGenDriven: !data?.customProduct,
          shouldFetchCustomProductOptions: data?.customProduct,
          ...variables,
          selectedOptionIds: variables?.selectedOptionIds || []
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
        context: {
          fetchOptions: {
            method: "POST"
          }
        }
      });

      //SR-72(Filter size options for custom rugs)
      const lineItemResponse = data?.customProduct
        ? filterCustomRugOptionTypes(productLineItem)
        : productLineItem;

      return lineItemResponse as ProductLineItem;
    },
    [
      client,
      data?.id,
      _fullSkuId,
      data?.personalizeInfo?.monogrammable,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      saleContextFilter,
      internationalFlag,
      locale,
      yn(env.FEATURE_SKIP_PAGE_RELOAD) ? currentUserType : userType,
      pageContent
    ]
  );

  const lazyQueryLineItemSku = useCallback(
    async variables => {
      setLoading(true);
      const optionIds = variables?.selectedOptionIds;

      const {
        data: { lineItemSku }
      } = await client.query({
        query: queryLineItemSku,
        variables: {
          productId: data?.id,
          fullSkuId: _fullSkuId,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: variables?.qty ?? 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku: true,
          nextGenDriven: !data?.customProduct,
          shouldFetchCustomProductOptions: data?.customProduct,
          ...variables,
          selectedOptionIds: optionIds
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });

      setLoading(false);
      return lineItemSku as ProductSku;
    },
    [
      client,
      data?.id,
      data?.personalizeInfo?.monogrammable,
      data?.onSale,
      data?.customProduct,
      _fullSkuId,
      yn(env.FEATURE_SKIP_PAGE_RELOAD) ? currentUserType : userType,
      postalCode,
      currencyCode,
      params?.productId,
      saleContextFilter,
      siteId,
      measureSystem,
      internationalFlag,
      locale
    ]
  );

  const fetchOptionStatus = useCallback(
    async variables => {
      const {
        data: { productLineItem }
      } = await client.query({
        query: queryLineItemLiteV3,
        variables: {
          productId: data?.id,
          fullSkuId: _fullSkuId,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType,
          monogrammable:
            variables?.monogrammable ?? data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          countryCode: country,
          qty: 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku:
            data?.priceRangeDisplay?.hasOnlyOneSku ||
            !data?.productLineItem?.availableOptions?.length
              ? true
              : false,
          nextGenDriven: !data?.customProduct,
          shouldFetchCustomProductOptions: data?.customProduct,
          ...variables
        },
        errorPolicy: "all"
      });
      return productLineItem as ProductLineItem;
    },
    [
      client,
      data?.id,
      fullSkuId,
      data?.personalizeInfo?.monogrammable,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      saleContextFilter,
      internationalFlag,
      locale,
      userType,
      pageContent
    ]
  );

  const resolveSku = (
    selectedOptionIds,
    customProductOptions,
    fullSkuId = undefined
  ) => {
    async function fetchQuery() {
      const optionIds = selectedOptionIds;

      const lineItemSkuResponse = await lazyQueryGetSku({
        fullSkuId,
        selectedOptionIds: optionIds,
        ...customProductOptions
      });
      setSkuDetails(lineItemSkuResponse);
      if (params?.productId === data?.id) {
        setPreConfiguredSku?.(lineItemSkuResponse?.fullSkuId || "");
      }
      setSkuLoading(false);
    }
    fetchQuery();
  };

  const resolveSkuDetails = (
    selectedOptionIds,
    lineItemResponse,
    data,
    lineItem
  ) => {
    async function fetchQuery() {
      setLoading(true);
      const optionIds = selectedOptionIds;
      const customProductOptions = getCustomProductOptions({
        lineItem,
        customOptions
      });

      const lineItemSkuResponse = await lazyQueryLineItemSku({
        fullSkuId: undefined,
        selectedOptionIds: optionIds,
        shouldFetchSku: true,
        qty: refetchOptions?.qty,
        ...customProductOptions
      });
      const combinedResponse = {
        ...lineItemResponse,
        sku: lineItemSkuResponse
      };
      setLineItemSku({ sku: lineItemSkuResponse });
      setIsCustomRug(false);
      setLineItem(combinedResponse);
      checkRideAlongData(combinedResponse);

      if (
        checkIsMultiSkuProduct(
          lineItemSkuResponse?.fulfillmentEta?.fullSkuId,
          data?.metaProduct
        )
      ) {
        getMultiSkuComponents(data?.id, selectedOptionIds, postalCode);
      }
    }
    fetchQuery();
  };

  // decouple line item image code
  const { imageUrl = "", refetchLineItemImage } = useProductImage({
    productId: data?.id,
    options:
      refetchOptions?.selectedOptionIds?.length >= selectedOptions?.length
        ? refetchOptions?.selectedOptionIds
        : getSelectedOptions(lineItem, selectedSwatches, lineItem)?.map(a => ({
            id: a
          })),
    siteId,
    locale
  });

  const productImageUrl = imageUrl ?? data?.productLineItem?.image?.imageUrl;

  const lazyQueryMultiSkuComponents = useCallback(
    async (
      productId: string,
      selectedOptionIds: string[],
      siteId: string,
      postalCode: string,
      qty: number
    ): Promise<MultiSkuComponentsResponse> => {
      const optionIds = selectedOptionIds;
      setLoading(true);

      const {
        data: { pullMultiSkuComponents }
      } = await client.query({
        query: queryMultiSkuComponentsV2,
        variables: {
          productId,
          selectedOptionIds: optionIds,
          siteId,
          currencyCode:
            countryCurrencyMapper[
              getCountryCodeFromPostalCodeAndSiteId(postalCode, siteId)
            ],
          countryCode: getCountryCodeFromPostalCodeAndSiteId(
            postalCode,
            siteId
          ),
          postalCode,
          locale,
          qty,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType
        }
      });
      setMultiSkuComponentsDataV2(pullMultiSkuComponents);
      setLoading(false);

      return pullMultiSkuComponents ?? {};
    },
    [
      client,
      locale,
      yn(env.FEATURE_SKIP_PAGE_RELOAD) ? currentUserType : userType
    ]
  );

  const getMultiSkuComponents = useCallback(
    async (
      productId: string,
      selectedOptionIds: string[],
      postalCode: string,
      qty?: number
    ) => {
      setLoading(true);

      await lazyQueryMultiSkuComponents(
        productId,
        selectedOptionIds,
        siteId,
        postalCode,
        qty || refetchOptions?.qty
      );

      setLoading(false);
    },
    [lazyQueryMultiSkuComponents, siteId, refetchOptions?.qty]
  );

  const checkRideAlongData = useCallback(
    skuInfoResponse => {
      if (skuInfoResponse?.sku?.info?.hasCasingSkus) {
        setRideAlongData?.({
          triggerProductId: data?.id,
          casingProduct: skuInfoResponse?.sku?.info?.casingProduct,
          replacementCushionProduct:
            skuInfoResponse?.sku?.info?.replacementCushionProduct,
          selectedSizeId: selectedSize?.current
        });
      }

      if (
        data?.id === rideAlongData?.triggerProductId &&
        !skuInfoResponse.sku.info.hasCasingSkus
      ) {
        setRideAlongData?.(defaultRideAlongData);
      }
    },
    [data?.id, rideAlongData?.triggerProductId, setRideAlongData]
  );

  const onChangeCb = useCallback(
    (
      selectedOptions: ProductAvailableOption[],
      qty: number,
      unavailableOptionId?: string
    ): void => {
      setLoading(true);

      selectedOptions?.forEach(option => {
        if (option?.type === "Size") {
          selectedSize.current = option?.id;
        }
      });
      const optionIds = Array.from(
        new Set(selectedOptions?.map(selectedOption => selectedOption.id))
      );

      /**
       * set newly selected refetch options here
       * shouldFetchSku should only true when product is full configured i.e all configure options are selected
       */
      setRefetchOptions?.(refetchOptions => ({
        ...refetchOptions,
        fullSkuId: !optionIds?.length ? _fullSkuId : undefined,
        selectedOptionIds: optionIds?.length ? optionIds : [],
        unavailableOption: unavailableOptionId
          ? [unavailableOptionId]
          : undefined,
        qty,
        shouldFetchSku: lineItem?.availableOptions?.length === optionIds?.length
      }));

      return onOptionsSelected?.(!!selectedOptions);
    },
    [_fullSkuId, lineItem, setRefetchOptions]
  );

  const updateOptionsStatus = (allOptions, lineItem) => {
    if (!lineItem?.availableOptions) return lineItem;

    const swatchOption = allOptions.find(
      item => item.optionTypeId === "swatch"
    );
    setSwatchOptionStatus(swatchOption?.options);

    const typeToStatusMap = allOptions.reduce((map, item) => {
      map[item.type] = item.options;
      return map;
    }, {});

    lineItem.availableOptions = lineItem.availableOptions.map(optionSet => {
      const { type, options } = optionSet;

      // For Color or Fabric types
      if (type === "Color" || type === "Fabric") {
        const selectedOption = Object.keys(swatchOption?.options)?.find(
          key => swatchOption?.options?.[key] === "selected"
        );
        if (selectedOption) {
          const selectedOptIds = selectedOption?.split("_");
          return {
            ...optionSet,
            options: options.map(option => ({
              ...option,
              status: selectedOptIds?.includes(option.id)
                ? "selected"
                : "available"
            }))
          };
        }
        return optionSet;
      }

      // For other types
      const statusMap = typeToStatusMap[type];
      if (statusMap) {
        return {
          ...optionSet,
          options: options.map(option => ({
            ...option,
            status: statusMap[option.id] || option.status
          }))
        };
      }

      return optionSet;
    });
    return lineItem;
  };

  const getFinalSelectedOptionsStatus = (): string[] | null => {
    const finalSelectedOptions: string[] = [];
    const color = selectedOptions.find(option => option.type === "Color");
    const fabric = selectedOptions.find(option => option.type === "Fabric");
    if (!color || !fabric) {
      return null;
    }

    const swatchOptionId =
      `${color.id}_${fabric.id}` in swatchOptionStatus
        ? `${color.id}_${fabric.id}`
        : `${fabric.id}_${color.id}` in swatchOptionStatus
        ? `${fabric.id}_${color.id}`
        : null;

    if (!swatchOptionId) return null;

    selectedOptions.forEach(option => {
      if (option.id !== color.id && option.id !== fabric.id) {
        finalSelectedOptions.push(option.id);
      }
    });

    finalSelectedOptions.push(swatchOptionId);

    return finalSelectedOptions;
  };

  const refetchLineItemDetails = async () => {
    setSkuDetails({ fullSkuId: null, components: [] });

    const defaultOptions = !refetchOptions?.selectedOptionIds
      ? {
          fullSkuId: _fullSkuId || undefined,
          selectedOptionIds: undefined,
          postalCode,
          currencyCode,
          measureSystem,
          qty: 1
        }
      : { ...refetchOptions, postalCode, currencyCode, measureSystem };

    // customOptions only gets set if customProduct
    // map defaultOptions with customOptions
    let finalSelectedOptions = refetchOptions?.selectedOptionIds?.length
      ? refetchOptions?.selectedOptionIds
      : [];

    // TO REFETCH LINEITME IMAGES START
    const options = {
      selectedOptionIds: finalSelectedOptions
    };

    refetchLineItemImage(options);
    // TO REFETCH LINEITME IMAGES END

    const customProductOptions = getCustomProductOptions({
      lineItem,
      customOptions
    });

    let finalOptions: any = {
      ...defaultOptions,
      selectedTrimOptionIds: selectedRugTrimSwatchIds,
      shouldFetchSku:
        lineItem?.availableOptions?.length === finalSelectedOptions?.length,
      selectedOptionIds: finalSelectedOptions,
      productId: data?.id,
      ...(data?.customProduct ? { ...customProductOptions } : {})
    };

    const fetchOptionStatusIds =
      getFinalSelectedOptionsStatus() ?? finalSelectedOptions;
    let allOptionsStatusResponse = await fetchOptionStatus({
      ...finalOptions,
      selectedOptionIds: fetchOptionStatusIds
    });
    let updatedLineItem = { ...lineItem };
    let response = updateOptionsStatus(
      allOptionsStatusResponse?.availableOptionsStatus?.allOptions,
      updatedLineItem
    );

    if (response) {
      setLineItem(prev => ({ ...prev, ...response }));
      updatedLineItem = { ...lineItem, ...response };
      setLoading(false);
    }

    if (finalOptions?.unavailableOption?.length && response) {
      const selectedOpts = calculateSelectedOpts(response);
      if (updatedLineItem?.availableOptions?.length === selectedOpts?.length) {
        finalSelectedOptions = selectedOpts?.map(opt => opt?.id);
        finalOptions.selectedOptionIds = finalSelectedOptions;
      }
    }

    let isProductFullyConfigured = false;
    const isOtherOptionsSelected =
      updatedLineItem?.availableOptions?.length ===
      finalOptions?.selectedOptionIds?.length;

    if (data?.customProduct) {
      const lengthFeet = customOptions?.length?.feet;
      const widthFeet = customOptions?.width?.feet;
      const diameterFeet = customOptions?.diameter?.feet;
      const isLengthAndWidthConfigured = lengthFeet !== "" && widthFeet !== "";
      const isDiameterConfigured =
        diameterFeet !== "" || diameterFeet === undefined;
      if (
        (isLengthAndWidthConfigured || isDiameterConfigured) &&
        isOtherOptionsSelected
      ) {
        isProductFullyConfigured = true;
      }
    } else {
      isProductFullyConfigured = response && isOtherOptionsSelected;
    }

    if (isProductFullyConfigured) {
      resolveSkuDetails(finalSelectedOptions, response, data, lineItem);
      resolveSku(finalSelectedOptions, customProductOptions);
    }
  };

  useDidMountEffect(() => {
    refetchLineItemDetails();
  }, [
    refetchOptions,
    customOptions,
    isMonogrammed,
    currencyCode,
    postalCode,
    measureSystem,
    locale
  ]);

  const lazyQueryAddOnGetSku = useCallback(
    async variables => {
      const {
        data: { getSku }
      } = await client.query({
        query: querGetSku,
        variables: {
          productId: variables?.productId,
          userType: yn(env.FEATURE_SKIP_PAGE_RELOAD)
            ? currentUserType
            : userType,
          postalCode,
          currencyCode: currencyCode,
          qty: 1,
          filter: null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          fullSkuId: undefined,
          selectedOptionIds: variables?.selectedOptionIds
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return getSku as GetSkuResponse;
    },
    [
      client,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      internationalFlag,
      locale,
      yn(env.FEATURE_SKIP_PAGE_RELOAD) ? currentUserType : userType,
      pageContent
    ]
  );

  const onAddonConfigChangeCb = useCallback(
    (addOnProduct: AddOnDataType, addOnConfigModes: AddOnConfigModes): void => {
      async function fetchAddOnLineItemQuery() {
        setLoading(true);
        const { panelProdComputation, mainItemOptionsChanged } =
          addOnConfigModes;
        const optionIds = addOnProduct?.selectedOptions?.map(
          selectedOption => selectedOption.id
        );

        const addOnData = data?.productAddons?.productAddonsInfo.find(
          addOn => addOn?.id === addOnProduct?.productId
        );

        const [
          {
            data: {
              productImage: { imageUrl }
            }
          },
          response
        ] = await Promise.all([
          refetchAddOnLineItemImage({
            productId: addOnProduct?.productId ?? "",
            selectedOptionIds: optionIds
          }),
          lazyQueryLineItem({
            productId: addOnProduct?.productId,
            fullSkuId: undefined,
            selectedOptionIds: optionIds?.length ? optionIds : [],
            postalCode: postalCode,
            monogrammable: false,
            qty: addOnProduct?.qty,
            shouldFetchSku: addOnProduct?.optionsLength === optionIds?.length
          })
        ]);

        if (response) {
          let lineItemSkuResponse: Maybe<ProductSku> = null;
          let getSkuResponse: Maybe<GetSkuResponse> = null;
          let skuAvailabilityStatusResponse: Maybe<
            ApolloQueryResult<Pick<Query, "skuAvailabilityStatus">>
          > = null;

          if (
            addOnData?.productLineItem?.availableOptions?.length ===
            optionIds?.length
          ) {
            const getSkuPromise = lazyQueryAddOnGetSku({
              productId: addOnProduct?.productId,
              selectedOptionIds: optionIds
            });

            [lineItemSkuResponse, getSkuResponse] = await Promise.all([
              lazyQueryLineItemSku({
                productId: addOnProduct?.productId,
                postalCode: postalCode,
                selectedOptionIds: optionIds?.length ? optionIds : undefined,
                qty: addOnProduct?.qty,
                shouldFetchSku:
                  addOnProduct?.optionsLength ===
                  addOnProduct?.selectedOptions?.length,
                filter: data?.productAddons?.productAddonsInfo?.[0]
                  ?.priceRangeDisplay?.nextGenDrivenOnSale
                  ? saleContextFilter
                  : null
              }),
              getSkuPromise
            ]);

            skuAvailabilityStatusResponse = await addOnQtyfetch?.({
              country,
              sku: getSkuResponse?.fullSkuId ?? "SKU_MISSING_UI"
            });
          }
          setLoading(true);
          setSkuLoading(true);
          setAddOnProduct({
            ...addOnProduct,
            fullSkuId: getSkuResponse?.fullSkuId,
            colorizedImgURL: imageUrl,
            spo: lineItemSkuResponse?.restrictions?.spo,
            preBillMessage: lineItemSkuResponse?.restrictions?.preBillMessage,
            pricing: lineItemSkuResponse?.info?.skuPriceInfo,
            itemOptions: response?.availableOptions,
            maxQuantity:
              skuAvailabilityStatusResponse?.data?.skuAvailabilityStatus
                ?.maxQuantity,
            mainItemProductId: data?.id
          });
          if (!panelProdComputation) {
            setAddOnsData(existingAddOns => {
              return createAddOnProductSet({
                existingAddOns,
                addOnProduct,
                mainItemOptionsChanged,
                fullSkuId: getSkuResponse?.fullSkuId,
                colorizedImgURL: imageUrl,
                spo: lineItemSkuResponse?.restrictions?.spo,
                preBillMessage:
                  lineItemSkuResponse?.restrictions?.preBillMessage,
                pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                qty: addOnProduct?.qty,
                itemOptions: response?.availableOptions,
                selectedOptions: addOnProduct?.selectedOptions,
                maxQuantity:
                  skuAvailabilityStatusResponse?.data?.skuAvailabilityStatus
                    ?.maxQuantity
              });
            });
          } else {
            setPanelProdConfigurationData({
              ...addOnProduct,
              fullSkuId: getSkuResponse?.fullSkuId ?? "",
              colorizedImgURL: imageUrl,
              spo: lineItemSkuResponse?.restrictions?.spo,
              preBillMessage: lineItemSkuResponse?.restrictions?.preBillMessage,
              pricing: lineItemSkuResponse?.info?.skuPriceInfo,
              qty: addOnProduct?.qty,
              itemOptions: response?.availableOptions ?? [],
              selectedOptions: addOnProduct?.selectedOptions,
              maxQuantity:
                skuAvailabilityStatusResponse?.data?.skuAvailabilityStatus
                  ?.maxQuantity
            });
          }
        }
        setLoading(false);
        setSkuLoading(false);
      }
      fetchAddOnLineItemQuery();
    },
    [_fullSkuId, lineItem, postalCode, addOnsData]
  );

  useEffect(() => {
    if (changeSwatchImageFromLineItem) {
      if (productImageUrl) {
        changeSwatchImageFromLineItem(productImageUrl);
      }
    }
  }, [productImageUrl]);

  useEffect(() => {
    if (isRideAlongProduct) {
      const selectedOptionIds = getSelectedOptions(
        lineItem,
        selectedSwatches,
        lineItem,
        false,
        rideAlongData?.selectedSizeId
          ? { Size: rideAlongData?.selectedSizeId }
          : {}
      );

      /**
       * set newly selected refetch options here
       * shouldFetchSku should only true when product is full configured i.e all configure options are selected
       */
      setRefetchOptions?.(refetchOptions => ({
        ...refetchOptions,
        fullSkuId: undefined,
        selectedOptionIds:
          selectedOptionIds?.length > 0 ? selectedOptionIds : undefined,
        shouldFetchSku:
          lineItem?.availableOptions?.length === selectedOptionIds?.length
      }));
      onOptionsSelected?.(true);
    }
  }, [rideAlongData]);

  const [pli, setPli] = useState<ProductLineItem>({
    ...lineItem,
    ...lineItemSku
  });

  const [isCustomRug, setIsCustomRug] = useState(
    pli?.customProductOptions?.customProductInformation?.customProductType ===
      "rug"
  );

  const { maxQuantity, skuAvailabilityStatus } = useQuantity({
    country,
    sku: skuDetails?.fullSkuId,
    maxQuantity: DEFAULT_MAX_QUANTITY
  });

  const addOnsExist = useMemo(
    () => !!data?.productAddons?.productAddonsInfo?.length,
    [data?.productAddons]
  );

  const shouldDisplayAddOns = checkDisplayAddOns(
    data?.productAddonDisplayOptions
  );

  const metaSkuPricing = useMemo(() => {
    return !isEmpty(multiSkuComponentsDataV2)
      ? multiSkuPriceMapper(multiSkuComponentsDataV2, country)
      : null;
  }, [multiSkuComponentsDataV2, country]);

  useEffect(() => {
    // this will be executed only for estore
    if (PDP_DEFAULT_QTY !== 0) {
      if (pli?.sku?.fulfillmentEta?.inventoryRemaining === "0") {
        setQty(0);
      }
    }
  }, [pli?.sku?.fulfillmentEta?.atgSkuId]);

  useDidMountEffect(() => {
    // This is only for PDP version of concierge
    if (PDP_DEFAULT_QTY !== 0) return;

    const inventoryRemaining = pli?.sku?.fulfillmentEta?.inventoryRemaining;
    const selectedOpts: ProductAvailableOption[] = (pli?.availableOptions ?? [])
      .map(availableOption =>
        availableOption?.options?.find(option => option?.status === "selected")
      )
      .filter(Boolean);

    // Check if all default options are selected
    const isAllAvailableOptionsSelected =
      pli?.availableOptions?.length === selectedOpts.length;

    const shouldUpdateQty =
      (isAllAvailableOptionsSelected && !isQtyChanged) ||
      (data?.customProduct &&
        isAllAvailableOptionsSelected &&
        isAllCustomOptionSelected &&
        !isQtyChanged);

    if (!shouldUpdateQty) {
      if (!isQtyChanged) {
        setQty(0);
      }
      return;
    }

    // Update quantity based on inventory and current quantity
    if (inventoryRemaining !== "0" && inventoryRemaining !== "" && qty === 0) {
      setQty(1);
    } else if (inventoryRemaining === "0" || inventoryRemaining === "") {
      setQty(0);
    } else if (qty > Number(inventoryRemaining)) {
      setQty(1);
    }
  }, [pli?.availableOptions, isAllCustomOptionSelected]);

  // for customProduct only
  useDidMountEffect(() => {
    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape, rugTrims } =
      pli?.customProductOptions?.customProductInformation ?? {};

    if (shape === "rectangle") {
      for (let rectangleOptionKey in rectangleOptions) {
        const { feet } = rectangleOptions[rectangleOptionKey];

        if (!feet) {
          setIsAllCustomOptionSelected(false);
          return;
        }
      }

      const { width, length } = rectangleOptions;

      const totalWidth = setFeetAsDecimal({
        feet: Number(width?.feet),
        inches: Number(width?.inches)
      });

      const totalLength = setFeetAsDecimal({
        feet: Number(length?.feet),
        inches: Number(length?.inches)
      });

      setIsAllCustomOptionSelected(
        checkIsAllCustomOptionsSelected({
          shape,
          rugTrims,
          rugTrim,
          totalLength,
          totalWidth
        })
      );
    } else if (shape === "round") {
      setIsAllCustomOptionSelected(
        checkIsAllCustomOptionsSelected({
          shape,
          rugTrims,
          rugTrim,
          feet: circleOptions?.feet
        })
      );
    }
  }, [
    pli?.customProductOptions?.customProductInformation?.shape,
    pli?.sku?.fulfillmentEta?.fullSkuId,
    rugTrim,
    customOptions
  ]);

  // For customProducts only
  useEffect(() => {
    if (
      pli?.sku?.info?.skuPriceInfo?.customProductErrorCode ===
        "INVALID_CUSTOM_PRODUCT_PRICE" ||
      customOptions?.length?.feet === "" ||
      customOptions?.width?.feet === ""
    ) {
      if (customOptions?.diameter?.feet !== "")
        return setIsCustomOptionSelectionValid(true);
      setIsCustomOptionSelectionValid(false);
    } else setIsCustomOptionSelectionValid(true);
  }, [pli?.sku?.info?.skuPriceInfo?.customProductErrorCode, customOptions]);

  //we can sets product addons data for concierge add to project functionality
  //---DO-NOT-REMOVE---
  useEffect(() => {
    if (pli?.sku?.fulfillmentEta && pli?.sku?.restrictions && isConcierge) {
      if (addOnsExist) {
        const addOnDataSet = [];
        addOnsData?.map(addon => {
          data?.productAddons?.productAddonsInfo?.map(prod => {
            if (
              addon?.productId === prod?.id &&
              addon?.productSelected &&
              addon?.qty > 0
            ) {
              addOnDataSet?.push({
                ...prod,
                spo: addon?.spo,
                preBillMessage: addon?.preBillMessage,
                fullSkuId: addon?.fullSkuId,
                pricing: addon?.pricing,
                qty: addon?.qty
              });
            }
          });
        });
        setAddonDataAddToProject(addOnDataSet);
      }
    }
  }, [addOnsData]);
  //////////////////////////////////////////////////////////////////

  // Enabling the RelatedProductActions On Addon addOnsData state updated
  useEffect(() => {
    selectedAddOnProduct && addOnsStateChangeCallback(selectedAddOnProduct);
  }, [panelProdConfigurationData, addOnsData]);
  //////////////////////////////////////////////////////////////////

  // for customProduct only
  const customProductConfig = useMemo(() => {
    if (!pli?.sku?.info?.skuPriceInfo?.sendCwCustomProductCode) {
      return {};
    }

    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape } = pli?.customProductOptions?.customProductInformation || {};

    const isGiftRegistryDialog =
      yn(env?.FEATURE_PDP_CART_BROKER) || giftRegistryDialog;

    if (shape === "round") {
      return createRoundCustomProductConfig(
        circleOptions,
        isGiftRegistryDialog,
        pli?.customProductOptions,
        rugTrim
      );
    } else if (shape === "rectangle") {
      return createRectangleCustomProductConfig(
        rectangleOptions,
        isGiftRegistryDialog,
        rugTrim,
        pli?.customProductOptions,
        pli?.sku?.info?.skuPriceInfo
      );
    } else {
      return {};
    }
  }, [customOptions, pli, rugTrim, giftRegistryDialog]);

  // for customProduct only
  const customSelectedOptions = useMemo(() => {
    let selectedOptions =
      pli.availableOptions
        ?.map(availableOption =>
          availableOption?.options?.find(
            option => option?.status === "selected"
          )
        )
        ?.filter(availableOption => !!availableOption) ?? [];

    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape, rugTrims } =
      pli?.customProductOptions?.customProductInformation || {};
    let allOptions: ProductAvailableOption | { type: string; value: string }[] =
      [...selectedOptions];

    if (shape === "round") {
      const { feet, inches } = circleOptions || {};
      const totalDiameter = `${feet}&#8242; ${inches || "0"} &#8243;`;
      allOptions = [...allOptions, { type: "Diameter", value: totalDiameter }];
    } else if (shape === "rectangle") {
      const { width, length } = rectangleOptions;
      const totalWidth = `${width?.feet}&#8242; ${
        width?.inches || "0"
      } &#8243;`;
      const totalLength = `${length?.feet}&#8242; ${
        length?.inches || "0"
      } &#8243;`;
      allOptions = [
        ...allOptions,
        { type: "Width", value: totalWidth },
        { type: "Length", value: totalLength }
      ];
    }
    if (rugTrims) {
      allOptions?.push({
        type: "Rug Trim",
        value: rugTrims?.find(({ id }) => id === rugTrim)?.value ?? ""
      });
    }
    return allOptions;
  }, [customOptions, pli, rugTrim]);

  //add all item to cart handler
  const handleAddAllProductChange = () => {
    const itemOptions = {
      productId: data?.id,
      sku:
        lineItem?.sku?.fulfillmentEta?.fullSkuId ||
        pli?.sku?.fulfillmentEta?.fullSkuId ||
        "",

      quantity: qty,
      brand,
      giftTo: giftCardTo ?? "",
      giftFrom: giftCardFrom ?? "",
      giftMessage: "",
      ...(monogramOrder?.fontCode ||
      monogramOrder?.fontColorCode ||
      monogramOrder?.borderCode
        ? { monogram: cleanMonogramOrder(monogramOrder) }
        : null),
      ...(!isEmpty(customProductConfig)
        ? { customInfo: customProductConfig }
        : null),
      lineItemIndex: lineItemIndex,
      displayName: data?.displayName,
      name: pli?.sku?.info?.name,
      imageUrl: productImageUrl,
      spo: pli?.sku?.restrictions?.spo,
      preBillMessage: pli?.sku?.restrictions?.preBillMessage
        ? pli.sku?.restrictions?.preBillMessage
        : pli.sku?.restrictions?.returnPolicyMessage || "",
      options: customSelectedOptions,
      salePriceLabel: data?.priceRangeDisplay?.salePriceLabel || "",
      pricing: pli?.sku?.info?.skuPriceInfo
    };
    handleAddAllItemToCart?.(itemOptions);
  };

  useEffect(
    () =>
      selectedRugTrimSwatchId
        ? setRugTrim(selectedRugTrimSwatchId)
        : setRugTrim(""),
    [selectedRugTrimSwatchId]
  );

  useDidMountEffect(() => {
    if (lineItem) {
      setPli({ ...lineItem, ...lineItemSku });
    }
  }, [lineItem, lineItemSku]);

  useDidMountEffect(() => {
    if (
      addAllToCartFlag &&
      (lineItem?.sku?.fulfillmentEta?.fullSkuId ||
        pli?.sku?.fulfillmentEta?.fullSkuId) &&
      lineItemIndex !== undefined
    ) {
      if (qty === 0) {
        handleRemoveItemFromAddAll?.(lineItemIndex);
      } else {
        handleAddAllProductChange();
      }
    }
    onChangeCb?.(selectedOptions, qty);
  }, [qty, postalCode]);

  const calculateSelectedOpts = lineItem => {
    return (
      lineItem?.availableOptions
        ?.map(
          availableOption =>
            availableOption?.options?.find(
              option => option?.status === "selected"
            ) as ProductAvailableOption
        )
        ?.filter(availableOption => !!availableOption) ?? []
    );
  };

  const checkAddAllToCartConditions = (
    data,
    lineItem,
    selectedOpts,
    isAllCustomOptionSelected,
    handleAddAllProductChange,
    handleRemoveItemFromAddAll,
    lineItemIndex
  ) => {
    if (
      ((!data?.customProduct &&
        lineItem?.availableOptions?.length === selectedOpts?.length) ||
        (data?.customProduct &&
          lineItem?.availableOptions?.length === selectedOpts?.length &&
          isAllCustomOptionSelected)) &&
      (lineItem?.sku?.fulfillmentEta?.fullSkuId ||
        pli?.sku?.fulfillmentEta?.fullSkuId)
    ) {
      handleAddAllProductChange();
    } else if (lineItemIndex !== undefined) {
      handleRemoveItemFromAddAll?.(lineItemIndex);
    }
  };

  const handleGiftCardValueExceed = (
    selectedOpts,
    qty,
    setGiftCardValueExceed
  ) => {
    if (
      selectedOpts?.length > 0 &&
      lineItem?.sku?.info?.name?.includes("Gift Card")
    ) {
      const totalAmount =
        Number(selectedOpts?.[0]?.value?.replace("$", "")) * qty;
      setGiftCardValueExceed(totalAmount > 10000);
    }
  };

  const checkMonogramOrder = (monogramOrder, setIsMonogrammed) => {
    if (monogramOrder) {
      const { description, lines } = monogramOrder;
      const isMonogrammed = !!description && !!lines && !!lines.length;
      if (setIsMonogrammed) setIsMonogrammed(isMonogrammed);
    }
  };

  useDidMountEffect(() => {
    const selectedOpts = calculateSelectedOpts(lineItem);

    selectedIds.current = selectedOpts;
    setSelectedOptions(selectedOpts);

    if (addAllToCartFlag) {
      checkAddAllToCartConditions(
        data,
        lineItem,
        selectedOpts,
        isAllCustomOptionSelected,
        handleAddAllProductChange,
        handleRemoveItemFromAddAll,
        lineItemIndex
      );
    }

    handleGiftCardValueExceed(selectedOpts, qty, setGiftCardValueExceed);
    checkMonogramOrder(monogramOrder, setIsMonogrammed);
  }, [
    lineItem?.availableOptions,
    monogramOrder,
    customProductConfig,
    pli?.sku?.fulfillmentEta?.fullSkuId
  ]);

  const isMultiSku = id => {
    return id ? ["m", "M"]?.includes(id[0]) : false;
  };

  const getSelectedIds = selectedOptionsSet => {
    const uniqueOptionIds = new Set();
    selectedOptionsSet?.forEach(obj => {
      obj.matchedOptionIds.forEach(optionId => {
        uniqueOptionIds.add(optionId);
      });
    });

    const uniqueOptionIdsArray = Array.from(uniqueOptionIds);
    return uniqueOptionIdsArray;
  };

  const setOptionsVisibility = useCallback(
    optionsSet =>
      pli.availableOptions?.map(optionSet => {
        const matchingOptionSet = optionsSet?.find(
          secondOptionSet =>
            secondOptionSet.optionTypeId === optionSet.optionTypeId
        );

        const matchingOptionIds = optionSet?.options
          .filter(option => option.status === "selected")
          .map(option => option.id);

        const isOptionSetVisible = matchingOptionSet
          ? matchingOptionSet.options.some(option =>
              matchingOptionIds.includes(option.id)
            )
          : false;

        return {
          optionTypeId: optionSet.optionTypeId,
          optionType: optionSet.type,
          isOptionSetVisible,
          matchedOptionIds: matchingOptionIds
        };
      }),
    [pli]
  );

  const isAddOnFullyConfigured = () => {
    return addOnsData?.every(addOn => {
      const addOnSelectedOptions = getSelectedIds(
        setOptionsVisibility(addOn.selectedOptions)
      );
      return addOnSelectedOptions?.length === addOn.selectedOptions?.length;
    });
  };

  const isAddOnNotFullyConfigured = !isAddOnFullyConfigured();

  const isDisabled =
    !skuDetails?.fullSkuId ||
    pli?.availableOptions?.length !== selectedOptions?.length ||
    skuLoading ||
    qty <= 0 ||
    giftCardValueExceed ||
    (addOnsExist &&
      selectedAddOnsNotFullyConfigured &&
      isAddOnNotFullyConfigured) ||
    skuAvailabilityStatus?.availabilityStatus === "unavailable";

  // DO NOT TOUCH
  const isEnableCustomRug = useMemo(() => {
    return !(
      pli?.customProductOptions?.customProductInformation?.customProductType ===
        "rug" &&
      data?.customProduct &&
      loading
    );
  }, [
    pli?.customProductOptions?.customProductInformation,
    data.customProduct,
    loading
  ]);

  const showItemNumber = !isDisabled;

  const handleAddToGiftRegistryClick = useCallback(() => {
    setGiftRegistryDialog(true);
  }, []);

  const onProductConfigChange = (
    value: string,
    options: ProductAvailableOption[]
  ) => {
    const currentSelectedOption = options?.find(option => option?.id === value);

    if (currentSelectedOption?.status == "unavailable") {
      onChangeCb?.(selectedOptions, qty, currentSelectedOption?.id);
    } else {
      setIsQtyChanged(false);
      setSelectedOptions(prev => {
        selectedIds.current = [
          ...prev.filter(
            selectedOption =>
              !options.some(option => option.id === selectedOption.id)
          ),
          ...(currentSelectedOption ? [currentSelectedOption] : [])
        ];
        onChangeCb?.(selectedIds.current, qty);
        selectedOptions = selectedIds.current;
        return selectedIds.current;
      });
    }
    // Some event occurs in the child component and data needs to be sent to the parent
    // productOptionDropdownData(value); // Call the callback function from the parent and pass the data
  };

  const onProductSwatchConfigChange = (
    value: string,
    options: ProductAvailableOption[]
  ) => {
    if (options?.[0]?.status == "unavailable") {
      onChangeCb?.(selectedOptions, qty, value);
    } else {
      setIsQtyChanged(false);
      setSelectedOptions(prev => {
        selectedIds.current = [
          ...prev.filter(
            selectedOption =>
              !options.some(option => option.type === selectedOption.type)
          ),
          ...(options?.length ? [...options] : [])
        ];
        onChangeCb?.(selectedIds.current, qty);
        selectedOptions = selectedIds.current;
        return selectedIds.current;
      });
    }
    // Some event occurs in the child component and data needs to be sent to the parent
    // productOptionDropdownData(value); // Call the callback function from the parent and pass the data
  };

  const addOnsStateChangeCallback = addOnData => {
    const remainingAddOnsConfigState =
      addOnsData
        ?.filter(addOn => addOn?.productId !== addOnData?.productId)
        ?.map(addOn => {
          return {
            productId: addOn?.productId,
            productChecked: addOn?.productSelected,
            fullyConfigured:
              addOn?.optionsLength === addOn?.selectedOptions?.length &&
              addOn?.fullSkuId
          };
        }) ?? [];

    const selectedAddOnsConfigState = addOnData?.productSelected
      ? [
          ...remainingAddOnsConfigState,
          {
            productId: addOnData?.productId,
            productChecked: addOnData?.productSelected,
            fullyConfigured:
              addOnData?.optionsLength === addOnData?.selectedOptions?.length
          }
        ]
      : [...remainingAddOnsConfigState];

    const areAllSelectedAddOnsConfigured = selectedAddOnsConfigState
      ?.filter(addOn => addOn?.productChecked)
      ?.every(addOn => addOn?.fullyConfigured);

    setSelectedAddOnsNotFullyConfigured(!areAllSelectedAddOnsConfigured);
  };

  const handleAddonData = (
    addOnData: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => {
    const optionIds = addOnData?.selectedOptions?.map(
      selectedOption => selectedOption.id
    );

    const productAddOnData = data?.productAddons?.productAddonsInfo.find(
      addOn => addOn?.id === addOnData?.productId
    );
    if (
      optionIds?.length !==
      productAddOnData?.productLineItem?.availableOptions?.length
    ) {
      setSelectedAddOnsNotFullyConfigured(false);
    } else {
      setSelectedAddOnsNotFullyConfigured(true);
    }
    setSelectedAddOnProduct(addOnData);
    onAddonConfigChangeCb?.(addOnData, addOnConfigModes);
  };

  const onRugConfigChange = (value: string) => {
    setRugTrim(value);
  };

  const handleDiameterInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        diameter: { ...prevCustomOptions?.diameter, feet: value || "" }
      })),
    []
  );

  const handleDiameterInInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        diameter: { ...prevCustomOptions?.diameter, inches: value }
      })),
    [customOptions]
  );

  const handleLengthInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        length: { ...prevCustomOptions?.length, feet: value }
      })),
    []
  );

  const handleLengthInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        length: { ...prevCustomOptions?.length, inches: value }
      })),
    []
  );

  const handleWidthInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        width: { ...prevCustomOptions?.width, feet: value }
      })),
    []
  );

  const handleWidthInInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        width: { ...prevCustomOptions?.width, inches: value }
      })),
    []
  );

  const handleCloseAddToCartDialog = useCallback(() => {
    setConfirmed(false);
    setCartDialog(false);
  }, []);

  /** @deprecated use FEATURE_USE_QUANTITY=true with useQuantity() */
  const getQtyValue = useMemo(() => {
    const inventory =
      pli?.sku?.fulfillmentEta?.inventoryRemaining === null
        ? DEFAULT_MAX_QUANTITY
        : pli?.sku?.fulfillmentEta?.inventoryRemaining ?? 0;
    if (
      pli?.sku?.info?.maxOrderQty !== undefined &&
      pli?.sku?.info?.maxOrderQty !== null &&
      pli?.sku?.info?.maxOrderQty > 0 &&
      pli?.sku?.info?.maxOrderQty < Number(inventory)
    ) {
      return pli?.sku?.info?.maxOrderQty;
    }
    if (
      data?.metaProduct === true &&
      isMultiSku(pli?.sku?.fulfillmentEta?.fullSkuId)
    ) {
      return multiSkuComponentsDataV2?.components?.reduce(
        (minInventory, multiSkuComponent) =>
          Number(multiSkuComponent?.inventory?.inventoryRemaining) <
          Number(minInventory)
            ? Number(multiSkuComponent?.inventory?.inventoryRemaining)
            : minInventory,
        DEFAULT_MAX_QUANTITY
      );
    }
    return !pli.sku?.fulfillmentEta?.inventoryRemaining
      ? DEFAULT_MAX_QUANTITY
      : inventory;
  }, [pli?.sku, multiSkuComponentsDataV2?.components]);

  const getLocationSearch = () => {
    if (processEnvServer) {
      const req = getReqContext();
      return req?.path + req?.query?.toString();
    } else {
      const location = useLocation();
      return `${location?.pathname + location?.search}`;
    }
  };

  /* this details are used in concierge side for product add to project
    ---DO-NOT-REMOVE---
  */
  const productDetails = useMemo(() => {
    return { productId: data?.id, imageUrl: productImageUrl };
  }, [data?.id, productImageUrl]);
  /* ---------------------------------------------------------------- */

  const configureAvailableOptions = useMemo(() => {
    return options => {
      return options.map(option =>
        checkOptionExistsInParentSelectedOptions(
          parentChosenLineItemOptions,
          parentChosenLineItemOptionIds,
          options,
          { ...option }
        )
      ) as ProductAvailableOption[];
    };
  }, [parentChosenLineItemOptions]);

  const getSelectedAddOnProdIds = (addOnsData: AddOnDataType[]): string[] => {
    return (
      addOnsData
        ?.filter(addOn => addOn?.productSelected)
        ?.map(addOn => addOn?.productId)
        ?.filter((id): id is string => !!id) ?? []
    );
  };

  const getPanelProd = (selectedAddOnProdIds: string[], data) => {
    const [panelProd] =
      data?.productAddons?.productAddonsInfo?.filter(
        addOn => !selectedAddOnProdIds?.includes(addOn?.id)
      ) ?? [];
    return panelProd;
  };

  const getPreSelectedOptions = (lineItem, panelProd) => {
    return lineItem?.availableOptions
      ?.map(optionSet => {
        const matchingOptionSet =
          panelProd?.productLineItem?.availableOptions?.find(
            secondOptionSet =>
              secondOptionSet?.optionTypeId === optionSet?.optionTypeId
          );
        const matchingOptionIds = optionSet?.options
          ?.filter(option => option?.status === "selected")
          ?.map(option => option?.id);
        return matchingOptionSet?.options?.filter(option =>
          matchingOptionIds?.includes(option?.id)
        );
      })
      ?.filter(opt => opt !== undefined)
      ?.reduce((acc, currentValue) => {
        return acc?.concat(currentValue || []);
      }, []);
  };

  const handleAddonDataUpdate = (
    panelProd,
    preSelectedOptions: ProductAvailableOption[],
    handleAddonData: Function
  ) => {
    const panelProdSingularPreSelectedOptions =
      panelProd?.productLineItem?.availableOptions
        ?.map(opt => opt?.options?.find(opt => opt?.status === "selected"))
        ?.filter(opt => opt) as ProductAvailableOption[];

    handleAddonData(
      {
        productId: panelProd?.id,
        productSelected: false,
        selectedOptions:
          preSelectedOptions ?? panelProdSingularPreSelectedOptions,
        optionsLength: panelProd?.productLineItem?.availableOptions?.length ?? 0
      },
      {
        panelProdComputation: true
      }
    );
  };

  const getAddOnCartSet = (addOnsData: AddOnDataType[], data) => {
    const addOnCartSet: addOnDataType[] = [];
    addOnsData?.forEach(addon => {
      data?.productAddons?.productAddonsInfo?.forEach(prod => {
        if (addon?.productId === prod?.id && addon?.productSelected) {
          addOnCartSet?.push({
            ...prod,
            productId: prod?.id,
            spo: addon?.spo,
            preBillMessage: addon?.preBillMessage,
            fullSkuId: addon?.fullSkuId,
            pricing: addon?.pricing,
            qty: addon?.qty
          });
        }
      });
    });
    return addOnCartSet;
  };

  const handleAddToCartClick = () => {
    if (!skuDetails?.fullSkuId) return;

    if (addOnsExist) {
      const selectedAddOnProdIds = getSelectedAddOnProdIds(addOnsData);
      const panelProd = getPanelProd(selectedAddOnProdIds, data);

      setPanelProdData(panelProd);

      const preSelectedOptions = getPreSelectedOptions(lineItem, panelProd);

      if (panelProd) {
        handleAddonDataUpdate(panelProd, preSelectedOptions, handleAddonData);
      }

      const addOnCartSet = getAddOnCartSet(addOnsData, data);
      setAddonDataAddToCart(addOnCartSet);
    }
    setCartDialog(true);
  };

  const handleAddToWishlistClick = async () => {
    if (pli?.sku?.fulfillmentEta && !isDisabled) {
      setWishlistDialog(true);
      triggerAddToWishlistAnalyticsEvent(data, skuDetails, qty, pli);
    }
  };

  const showInStockButtons = () => {
    return !pli?.sku?.restrictions?.serviceSku;
  };

  const showInStockButton = heroImageLevel => {
    const shouldShowButton = () =>
      showInStockButtons() &&
      data?.instock?.hasInStock &&
      data?.instock?.showInStockButton;

    if (!heroImageLevel) {
      return shouldShowButton();
    }

    if (!isShellProduct) {
      return shouldShowButton();
    }

    if (data?.metaProduct) {
      return shouldShowButton();
    }

    return false;
  };

  const showSaleButton = () => {
    return (
      showInStockButtons() &&
      data?.sale?.hasSale &&
      data?.sale?.showSaleButton &&
      parseFloat(String(data?.priceRangeDisplay?.saleInfo?.percentSaleSkus)) !==
        SALE_PERCENT_100 &&
      !window?.location?.href?.includes("sale=true") &&
      !window?.location?.href?.includes("sale=sale")
    );
  };

  const summarizedQuantity = useMemo(() => {
    return maxQuantity < qty ? maxQuantity : qty;
  }, [maxQuantity, qty]);

  const percentSaleSkus = Number(
    data?.priceRangeDisplay?.saleInfo?.percentSaleSkus
  );

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    percentSaleSkus,
    data?.priceRangeDisplay?.saleInfo?.memberSavings?.memberSavingsMin,
    data?.priceRangeDisplay?.saleInfo?.memberSavings?.memberSavingsMax
  );

  const getPreBillMessage = () => {
    if (multiSkuComponentsDataV2?.isSPO) {
      return multiSkuComponentsDataV2?.multiSkuSpoPreBillMessage;
    }
    if (pli?.sku?.restrictions?.preBillMessage) {
      return pli?.sku?.restrictions?.preBillMessage;
    }
    return pli?.sku?.restrictions?.returnPolicyMessage || "";
  };

  const getRelatedProductActionDisabled = (): boolean => {
    return (
      !skuDetails?.fullSkuId ||
      skuAvailabilityStatus?.availabilityStatus === "unavailable" ||
      ((multiSkuComponentsDataV2?.components || []).length > 0
        ? !!(
            addOnsExist &&
            selectedAddOnsNotFullyConfigured &&
            selectedAddOnProduct?.productSelected
          )
        : pli?.customProductOptions?.customProductInformation
        ? !(
            showItemNumber &&
            isCustomOptionSelectionValid &&
            skuDetails?.fullSkuId &&
            isEnableCustomRug
          )
        : isDisabled)
    );
  };

  return {
    inStockReady,
    isShellProduct,
    heroImageLevel,
    monogramOrder,
    data,
    isMetaProduct: data?.metaProduct,
    pli,
    authenticated,
    maxOrderQty: yn(env?.FEATURE_USE_QUANTITY)
      ? maxQuantity
      : Number(getQtyValue),
    customProductConfig,
    userType,
    postalCode: postalCode ?? cookies?.pc,
    swatchShipMessage: pageContent?.swatchShipMessage,
    loading,
    giftCardTo,
    giftCardFrom,
    rugTrim,
    customOptions,
    isAllCustomOptionSelected,
    isDisabled,
    monogrammable: data?.personalizeInfo?.monogrammable,
    country,
    isConcierge,
    showItemNumber,
    isCustomOptionSelectionValid,
    params,
    multiSkuComponentsDataV2,
    skuAvailabilityStatus,
    giftCardValueExceed,
    spoOverrideMessage,
    skuDetails,
    selectedOptions,
    qty: summarizedQuantity,
    addOnsExist,
    isCustomRug,
    selectedIds,
    customSelectedOptions,
    addonDataAddToProject,
    productDetails,
    shouldDisplayAddOns,
    addOnsData,
    swatch: data?.swatch,
    postalCodeDialog,
    internationalFlag,
    surchargeInfoDialog,
    cartDialog,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    wishlistDialog,
    giftRegistryDialog,
    productImageUrl,
    monogramDialog,
    lineItemIndex,
    isLineItemImageUrlFailed,
    lineItem,
    swatchGroups,
    skuInventoryMessage: lineItem?.sku?.fulfillmentEta?.inventoryMessage || "",
    swatchOptionStatus,
    isUserTouchConfig: !!refetchOptions?.selectedOptionIds?.length,

    showInStockButton,
    isMultiSku,
    configureAvailableOptions,
    onProductConfigChange,
    onProductSwatchConfigChange,
    // setIsSwatchImageUpdated,
    setGiftCardTo,
    setGiftCardFrom,
    onRugConfigChange,
    handleDiameterInFeetChange,
    handleDiameterInInchesChange,
    handleWidthInFeetChange,
    handleWidthInInchesChange,
    handleLengthInFeetChange,
    handleLengthInchesChange,
    setSurchargeInfoDialog,
    setMonogramDialog,
    setMonogramOrder,
    setPostalCodeDialog,
    setQty,
    setIsQtyChanged,
    handleAddToCartClick,
    handleAddToWishlistClick,
    handleAddToGiftRegistryClick,
    handleAddonData,
    showSaleButton,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange: onAddonConfigChangeCb,
    setWishlistDialog,
    setGiftRegistryDialog,
    getLocationSearch,
    setIsLineItemImageUrlFailed,
    setRideAlongData,
    selectedAddOnProduct,
    dynamicMemberSavingsText,
    getPreBillMessage,
    getRelatedProductActionDisabled
  };
};
